import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { useContext, useState } from "react";
import { Context } from "contexts/contextState";
import { User } from "models/User";
import { useNavigate } from "react-router-dom";
import { errorAlert, successAlert } from "views/components/Alerts";

export function EditCustomerSetting(props) {
  const navigate = useNavigate();
  const { context } = useContext(Context);
  const [confirmAccountDelete, setConfirmAccountDelete] = useState(false);

  async function handleActiveDeactivateAccount() {
    const customer = new User(props.customer);
    customer.active = !customer.active;
    const customerResponse = await context.userRepository.saveUser(customer);
    props.setCustomer(customerResponse);
  }

  async function handleDeleteCustomerAccount() {
    if (confirmAccountDelete) {
      try {
        const account = await context.userRepository.findUserById(props.customer.id);
        if (account.permission?.root) {
          errorAlert("Error!", "This account is the root admin and cannot be deleted.");
        } else {
          await context.userRepository.delete(props.customer.id);
          navigate("/admin/customers/all");
          successAlert("Success!", "The customer has been deleted.");
        }
      } catch (error) {
        console.log("handleDeleteCustomerAccount", error);
        errorAlert("Error!", "Failed to delete a customer in our database. Please check your connection and try again. If the issue persists, please contact our support team for assistance.");
      }
    } else {
      errorAlert("Error!", "Confirm to delete \"I want to delete my account.\"");
    }
  }

  return (
    <Card id="delete-account">
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Delete Account</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Once you delete your account, there is no going back. Please be certain.
        </SoftTypography>
      </SoftBox>
      <SoftBox
        pb={3}
        px={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <SoftBox display="flex" alignItems="center" mb={{ xs: 3, sm: 0 }}>
          <Switch checked={confirmAccountDelete} onChange={() => setConfirmAccountDelete(previous => !previous)} />
          <SoftBox ml={2} lineHeight={0}>
            <SoftTypography display="block" variant="button" fontWeight="medium">
              Confirm
            </SoftTypography>
            <SoftTypography variant="caption" color="text">
              I want to delete my account.
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
          <SoftButton variant="outlined" color={props.customer.active ? "secondary" : "info"}
            onClick={handleActiveDeactivateAccount}
            disabled={!(context.permission.root || context.permission.customer.update)}
          >
            {props.customer.active ? "deactivate" : "active"}
          </SoftButton>
          <SoftBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
            <SoftButton variant="gradient" color="error" sx={{ height: "100%" }}
              onClick={handleDeleteCustomerAccount}
              disabled={!(context.permission.root || context.permission.customer.delete)}
            >
              delete account
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox mt="30px" />
    </Card>
  );
}

EditCustomerSetting.propTypes = {
  customer: PropTypes.object.isRequired,
  setCustomer: PropTypes.func.isRequired
}