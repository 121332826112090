import moment from "moment";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import sanitizeHtml from "sanitize-html";

export class FormatterService {
  formatPhoneNumber(phoneNumber) {
    if (!!phoneNumber) {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const number = phoneUtil.parseAndKeepRawInput(phoneNumber, "US");
      const formattedPhone = phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL)
      return formattedPhone;
    }
    return "N/A";
  }
  
  formatDate(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  formatCurrency(currency, value) {
   return new Intl.NumberFormat("en-US", { style: "currency", currency: currency || "CAD" }).format(Number(value));
  }

  removeAllHtml(text) {
    return sanitizeHtml(text, {
      allowedTags: [], // Remove all HTML tags
      allowedAttributes: {} // Remove all HTML attributes
    });
  }

  formatStringShortText(text, size = 80) {
    if (String(text).length <= size) {
      return String(text);
    } else {
      return String(text).substring(0, size) + "...";
    }
  }
}