import StatusCell from "components/layouts/ecommerce/orders/order-list/components/StatusCell";
import { FormatterService } from "services/FormatterService";
import { ActiveDisabled } from "views/components/ActiveDisabled";
import { CourseProductCell } from "views/courses/all/components/table/CourseProductCell";
import { CustomerAvatarCell } from "views/customers/all/components/table/CustomerAvatarCell";

const formatterService = new FormatterService();

/* eslint-disable react/prop-types */
export const CUSTOMER_DATA_TABLE = {
  columns: [
    { Header: "name", accessor: "name", width: "30%", Cell: ({ value: [name, image, email, createdDate] }) => <CustomerAvatarCell image={image || ""} createdDate={createdDate} name={name} description={email} /> },
    { Header: "phone", accessor: "phone" },
    { Header: "payment", accessor: "payment", Cell: ({ value }) => {
      if (["paid", "new"].includes(value)) {
        return <StatusCell icon="done" color="success" status={"Paid"} />;
      } else if (value === "unpaid") {
        return <StatusCell icon="attach_money" color="dark" status="Unpaid" />;
      } else {
        return <StatusCell icon="people" color="info" status="Need to pay" />;
      }
    }},
    // { Header: "active", accessor: "active", Cell: ({ value }) => <ActiveDisabled status={value} /> },
    { Header: "joined", accessor: "joined" },
    { Header: "action", accessor: "action" },
  ],
  rows: []
}

export const COURSE_TABLE = {
  columns: [
    { Header: "product", accessor: "product", width: "40%", Cell: ({ value: [name, image], ...rest }) => <CourseProductCell image={image || ""} createdDate={rest.row.original.createdDate} name={name} description={rest.row.original.shortDescription} /> },
    { Header: "price", accessor: "price", Cell: data => formatterService.formatCurrency(data.row.original.currency?.label || "CAD", data.value) },
    { Header: "status", accessor: "active", Cell: ({ value }) => <ActiveDisabled status={value} /> },
    { Header: "action", accessor: "action" },
  ],
  rows: [],
};

export const CUSTOMER_COURSE_TABLE = {
  columns: [
    { Header: "product", accessor: "product", width: "40%", Cell: ({ value: [name, image], ...rest }) => <CourseProductCell image={image || ""} createdDate={rest.row.original.createdDate} name={name} description={rest.row.original.shortDescription} /> },
    { Header: "payment", accessor: "payment" },
    { Header: "classroom", accessor: "classroom" },
    { Header: "certification", accessor: "certification" },
    { Header: "Consent", accessor: "consent" },
    { Header: "action", accessor: "action" },
  ],
  rows: [],
};

export const LESSON_TABLE_COLUMNS = [
  { name: "title", align: "left" },
  { name: "status", align: "center" },
  { name: "created", align: "center" },
  { name: "action", align: "right" }
];

export const CUSTOMER_PAYMENT_TABLE = {
  columns: [
    { Header: "name", accessor: "name", Cell: ({ value: [name, image, email, createdDate] }) => <CustomerAvatarCell image={image || ""} createdDate={createdDate} name={name} description={email} /> },
    { Header: "product", accessor: "product", Cell: ({ value: [name, image], ...rest }) => <CourseProductCell image={image || ""} createdDate={rest.row.original.createdDate} name={name} description={formatterService.formatStringShortText(rest.row.original.shortDescription, 30)} /> },
    { Header: "status", accessor: "status" },
    { Header: "price", accessor: "price", Cell: data => formatterService.formatCurrency(data.row.original.currency?.label || "CAD", data.value) },
    { Header: "price + tax", accessor: "totalPrice", Cell: data => formatterService.formatCurrency(data.row.original.currency?.label || "CAD", data.value) },
    { Header: "pay method", accessor: "paymentMethod" },
    { Header: "action", accessor: "action" },
  ],
  rows: [],
};