import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import Footer from "components/examples/Footer";
import DataTable from "components/examples/Tables/DataTable";
import { useContext, useEffect, useState } from "react";
import { Context } from "contexts/contextState";
import { MainHeader } from "views/components/MainHeader";
import { CourseActionCell } from "./components/table/CourseActionCell";
import { COURSE_TABLE } from "views/constants/tables";

export function CoursesView(props) {
  /****************** hooks ********************/
  const { context } = useContext(Context);
  const [firstPageLoader, setFirstPageLoader] = useState(true);
  const [courseTable, setCourseTable] = useState(COURSE_TABLE);

  /****************** functions ********************/
  
  async function handlePageLoader() {
    const [courseResponse, imagesResponse] = await Promise.all([
      context.courseRepository.findAllCourse(),
      context.fileStorageRepository.findAllImages()
    ]);

    setCourseTable(previous => ({
      ...previous,
      rows: courseResponse.map(course => ({
        ...course,
        product: [course.title, imagesResponse.find(image => image.id == course.thumbnailReference?.id)?.fileUrl ],
        level: course.courseLevel?.label,
        price: course.price,
        active: course.active,
        action: <CourseActionCell course={course} />,
      }))
    }));
  }

  /****************** useEffects ********************/

  useEffect(() => {
    // it will just execute once
    if (firstPageLoader) {
      setFirstPageLoader(false);
      // codding ...
      handlePageLoader();
    }
  }, []);

  return (
    <DashboardLayout>
      <MainHeader />
      <SoftBox my={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                All Courses
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Comprehensive catalog of available courses
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction="row">
              <Link to="/admin/courses/create">
                <SoftButton variant="gradient" color="info" size="small">
                  + new course
                </SoftButton>
              </Link>
            </Stack>
          </SoftBox>
          <DataTable
            canSearch
            table={courseTable}
            entriesPerPage={{
              defaultValue: 25,
              entries: [10, 25, 50, 100],
            }}
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}