import { Box, Card, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import Footer from "components/examples/Footer";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import Table from "components/examples/Tables/Table";
import FormField from "components/layouts/applications/wizard/components/FormField";
import { Context } from "contexts/contextState";
import { Course } from "models/Course";
import { LESSON_CONTENT_ENUM, LessonContent } from "models/Lesson";
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { handleFormData, handleSelectFormData } from "services/HandleService";
import { errorAlert, successAlert } from "views/components/Alerts";
import { MainHeader } from "views/components/MainHeader";
import { LessonContentActionCell } from "./components/LessonContentActionCell";
import { SoftDropzone } from "views/components/SoftDropzone";
import { ProgressBarModal } from "views/components/ProgressBarModal";
import { STORAGE_TYPE } from "models/FileStorage";
import SoftEditor from "components/SoftEditor";
import { Question } from "models/Question";
import { Answer } from "models/Answer";
import SoftBadge from "components/SoftBadge";
import { AnswerActionCell } from "views/questions/create/components/AnswerActionCell";
import { ANSWER_TABLE_COLUMNS } from "views/questions/create/CreateQuestionView";

// use this component to create new pages view
export function LessonView() {
  /****************** hooks ********************/
  const params = useParams();
  const { context } = useContext(Context);
  const [course, setCourse] = useState(new Course());
  const [lessonContentForm, setLessonContentForm] = useState(new LessonContent());
  const [firstPageLoader, setFirstPageLoader] = useState(true);
  const [lessonContentRows, setLessonContentRows] = useState([]);

  const [questionsSelector, setQuestionsSelector] = useState([]);
  const [archivesSelector, setArchivesSelector] = useState([]);

  const [archiveDisplaySelectOrUploadSelector, setArchiveDisplaySelectOrUploadSelector] = useState(true);
  const [questionDisplaySelectOrCreateSelector, setQuestionDisplaySelectOrCreateSelector] = useState(true);

  const [fileForm, setFileForm] = useState({ file: null });
  const [percent, setPercent] = useState(0);
  const [processing, setProcessing] = useState(false);

  // question 

  const [questionForm, setQuestionForm] = useState(new Question());
  const [answerForm, setAnswerForm] = useState(new Answer());

  /****************** functions ********************/
  // question start
  function handleAddAnswer() {
    const question = new Question(questionForm)
    if (!question.title || !answerForm.title) {
      return errorAlert("Error!", "Failed to create a new answer. Please make sure that the answer field is not empty and 'Is it correct answer' is selected");
    }
    question.answers.push(answerForm);
    setQuestionForm(question);
    setAnswerForm(new Answer());
    successAlert("Success!", "The new answer has been successfully added.");
  }

  function handleRemoveAnswerFromQuestion(answer) {
    const question = new Question(questionForm);
    question.answers = question.answers.filter(currentAnswer => currentAnswer.id != answer.id);
    setQuestionForm(question);
  }

  //question end

  async function handleLessonContentSubmit() {
    try {
      const lesson = course.lessons.find(lesson => String(lesson.id) == params.lessonId);
      // check if need to upload file before submit
      if (lessonContentForm.type == LESSON_CONTENT_ENUM.ARCHIVE && !archiveDisplaySelectOrUploadSelector) {
        setProcessing(true);
        const archiveUploadResponse = await context.fileStorageRepository.uploadFile(fileForm.file, STORAGE_TYPE.ARCHIVE, (progressPercent) => {
          setPercent(progressPercent);
        });
        lessonContentForm.archiveId = archiveUploadResponse.id;
        console.log(handleLessonContentSubmit.name, archiveUploadResponse);
      } else if (lessonContentForm.type == LESSON_CONTENT_ENUM.QUESTION && !questionDisplaySelectOrCreateSelector) { // create question
        const question = new Question(questionForm);
        if (!question.title || !question.answers.length || !question.answers.find(answer => answer.isCorrectAnswer)) {
          return errorAlert("Error!", "Failed to create a new question block. Please make sure that the question field is not empty and at least one answer and a correct answer is added to the answers list");
        }
        const responseQuestion = await context.questionRepository.save(question);
        lessonContentForm.questionId = responseQuestion.id;
      }
      lesson.lessonContents.push(lessonContentForm);
      const courseResponse = await context.courseRepository.save(course);
      console.log("handleLessonContentSubmit", courseResponse);
      successAlert("Success!", "The video lesson content has been successfully created.");
      setLessonContentForm(new LessonContent());
      setQuestionForm(new Question());
      setFileForm({ file: null });
      handlePageLoader();
    } catch (error) {
      console.log(error);
      errorAlert("Error!", "Failed to update a lesson content in our database. Please check your connection and try again. If the issue persists, please contact our support team for assistance.");
    } finally {
      setProcessing(false);
    }
  }

  async function handlePageLoader() {
    // load all registered questions and archive
    const [questionResponse, archiveResponse] = await Promise.all([
      context.questionRepository.findAllQuestions(),
      context.fileStorageRepository.findAllArchives()
    ]);
    // create the selects
    const parseQuestionsSelect = questionResponse.map(question => ({ label: question.title, value: question.id }));
    const parseArchivesSelect = archiveResponse.map(archive => ({ label: archive.fileName, value: archive.id }));
    setQuestionsSelector(parseQuestionsSelect);
    setArchivesSelector(parseArchivesSelect);
    // load content course
    const courseResponse = await context.courseRepository.findCourseById(params.courseId);
    setCourse(courseResponse);
    // create lesson content table
    const lesson = courseResponse.lessons.find(lesson => String(lesson.id) == params.lessonId);
    setLessonContentRows(lesson.lessonContents.map(content => ({
      title: ["", <SoftTypography variant="p" color="inherit">
        {content.title}
      </SoftTypography>],
      content:
        content.type == LESSON_CONTENT_ENUM.ARCHIVE ? archiveResponse.find(archive => archive.id == content.archiveId)?.fileName :
          content.type == LESSON_CONTENT_ENUM.VIDEO ? content.videoLink :
            content.type == LESSON_CONTENT_ENUM.TEXT ? context.formatterService.formatStringShortText(context.formatterService.removeAllHtml(content.text)) :
              questionResponse.find(question => question.id == content.questionId)?.title || "",
      type: content.type,
      action: <LessonContentActionCell archivesSelector={parseArchivesSelect} questionsSelect={parseQuestionsSelect} course={courseResponse} lesson={lesson} lessonContent={content} onRefresh={handlePageLoader} />
    })));
  }

  /****************** useEffects ********************/

  useEffect(() => {
    if (firstPageLoader) {
      setFirstPageLoader(false);
      handlePageLoader();
    }
  }, []);

  return (
    <DashboardLayout>
      <ProgressBarModal open={processing} percent={percent} />
      <MainHeader />
      <SoftBox my={3}>
        <Card sx={{ overflow: "visible" }}>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Edit Lesson Content
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                {course.lessons.find(lesson => String(lesson.id) == params.lessonId)?.title}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <Box display="flex" flexDirection="column" padding="20px" gap="10px">
            <Box>
              <FormField label="Lesson Content Title" placeholder="Example: 1 - Learn how to ..."
                value={lessonContentForm.title || ""} name="title" onChange={(event) => handleFormData(event, setLessonContentForm)}
              />
            </Box>
            <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SoftBox my={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Lesson Type
                </SoftTypography>
              </SoftBox>
              <SoftSelect
                placeholder="Select Type"
                value={LESSON_CONTENT_SELECT.find(select => select.value == lessonContentForm.type) || ""}
                options={LESSON_CONTENT_SELECT}
                onChange={(obj) => handleSelectFormData("type", obj.value, setLessonContentForm)}
              />
            </SoftBox>
            {/* text tab */}
            <Box display={lessonContentForm.type == LESSON_CONTENT_ENUM.TEXT ? "block" : "none"}>
              <SoftEditor defaultValue={lessonContentForm.text} onChange={(value) => handleSelectFormData("text", value, setLessonContentForm)} />
            </Box>
            {/* video tab */}
            <Box display={lessonContentForm.type == LESSON_CONTENT_ENUM.VIDEO ? "block" : "none"}>
              <FormField label="Youtube Link(private)" placeholder="https://..."
                value={lessonContentForm.videoLink || ""} name="videoLink" onChange={(event) => handleFormData(event, setLessonContentForm)}
              />
            </Box>

            {/* question tab selector */}
            <Box display={lessonContentForm.type == LESSON_CONTENT_ENUM.QUESTION ? "flex" : "none"} flexDirection="column" gap="20px">
              <Box display="flex" gap="20px" flex="1">
                <SoftBox display="flex" flex="1" flexDirection="column" justifyContent="flex-end" height="100%">
                  <SoftBox my={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Select Multiple Choice / Create Multiple Choice
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    placeholder="Select Multiple Choice / Create Multiple Choice"
                    value={QUESTION_SELECT.find(select => select.value == questionDisplaySelectOrCreateSelector)}
                    options={QUESTION_SELECT}
                    onChange={(obj) => setQuestionDisplaySelectOrCreateSelector(obj.value)}
                  />
                </SoftBox>
              </Box>
              {/* question tab select */}
              <SoftBox display={questionDisplaySelectOrCreateSelector ? "flex" : "none"} flexDirection="column" justifyContent="flex-end" height="100%">
                <SoftBox my={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Multiple Choice
                  </SoftTypography>
                </SoftBox>
                <SoftSelect
                  placeholder="Select Multiple Choice"
                  value={questionsSelector.find(select => select.value == lessonContentForm.questionId) || ""}
                  options={questionsSelector}
                  onChange={(obj) => handleSelectFormData("questionId", obj.value, setLessonContentForm)}
                />
              </SoftBox>
              {/* question tab create */}
              <SoftBox display={!questionDisplaySelectOrCreateSelector ? "flex" : "none"} flexDirection="column" justifyContent="flex-end" height="100%">
                <Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormField type="text" label="Question:" name="title" value={questionForm.title} placeholder="Please write here your question" onChange={(event) => handleFormData(event, setQuestionForm)} />
                    </Grid>
                  </Grid>
                  <Box mt="15px" />
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <FormField type="text" label="Answer:" name="title" value={answerForm.title} placeholder="Please write here your answer for the question above" onChange={(event) => handleFormData(event, setAnswerForm)} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box>
                        <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                          Is it correct answer:
                        </SoftTypography>
                        <RadioGroup defaultValue="NO" onChange={value => handleSelectFormData("isCorrectAnswer", value.target.value == "YES", setAnswerForm)}>
                          <Box display="flex" gap={8} mt={0.5} ml={2}>
                            <FormControlLabel value="YES" control={<Radio />} label="YES" />
                            <FormControlLabel value="NO" control={<Radio />} label="NO" />
                          </Box>
                        </RadioGroup>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box display="flex" justifyContent="end" py="20px">
                    <SoftButton variant="outlined" color="info" size="small"
                      onClick={handleAddAnswer}
                      disabled={!(context.permission.root || context.permission.question.update)}
                    >
                      Add Answer
                    </SoftButton>
                  </Box>
                  <SoftBox mt={1}>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" pb={3}>
                      <SoftBox lineHeight={1}>
                        <SoftTypography variant="h5" fontWeight="medium">
                          Answers
                        </SoftTypography>
                        <SoftTypography variant="button" fontWeight="regular" color="text">
                          List of answers for this question.
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                    <Table columns={ANSWER_TABLE_COLUMNS} rows={questionForm.answers.map(answer => ({
                      title: ["", <SoftTypography variant="p" color="inherit">{answer.title}</SoftTypography>],
                      correct: answer.isCorrectAnswer ? <SoftBadge variant="contained" color="success" size="xs" badgeContent="YES" container /> : <SoftBadge variant="contained" color="error" size="xs" badgeContent="NO" container />,
                      action: <AnswerActionCell answer={answer} onDelete={handleRemoveAnswerFromQuestion} />
                    }))} />
                  </SoftBox>
                </Box>
              </SoftBox>
            </Box>
            {/* archive tab */}
            <Box display={lessonContentForm.type == LESSON_CONTENT_ENUM.ARCHIVE ? "flex" : "none"} flexDirection="column" gap="20px">
              <Box display="flex" gap="20px" flex="1">
                <SoftBox display="flex" flex="1" flexDirection="column" justifyContent="flex-end" height="100%">
                  <SoftBox my={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Select File / Upload File
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    placeholder="Select File / Upload File"
                    value={ARCHIVE_SELECT.find(select => select.value == archiveDisplaySelectOrUploadSelector)}
                    options={ARCHIVE_SELECT}
                    onChange={(obj) => setArchiveDisplaySelectOrUploadSelector(obj.value)}
                  />
                </SoftBox>
              </Box>

              {/* select file */}
              <SoftBox display={archiveDisplaySelectOrUploadSelector ? "flex" : "none"} flex="1" flexDirection="column" justifyContent="flex-end" height="100%">
                <SoftBox my={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Select Archive
                  </SoftTypography>
                </SoftBox>
                <SoftSelect
                  placeholder="Select Archive"
                  value={archivesSelector.find(select => select.value == lessonContentForm.archiveId)}
                  options={archivesSelector}
                  onChange={(obj) => handleSelectFormData("archiveId", obj.value, setLessonContentForm)}
                />
              </SoftBox>
              {/* upload file */}
              <Box display={!archiveDisplaySelectOrUploadSelector ? "flex" : "none"} >
                <SoftBox display="flex" flex="1" flexDirection="column" mt={1} minWidth="480px">
                  <SoftBox my={2}>
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                        Select PDF:
                      </SoftTypography>
                    </SoftBox>
                    <SoftDropzone file={fileForm.file} options={{
                      addRemoveLinks: true,
                      maxFiles: 1, // Allow only one file
                      acceptedFiles: "application/pdf", // Accept only pdf files
                      dictDefaultMessage: "Drop a pdf file here or click to upload",
                      init: function () {
                        this.on("addedfile", (file) => {
                          if (this.files.length > 1) {
                            this.removeFile(this.files[0]);
                          }
                          handleSelectFormData("file", file, setFileForm);
                        });
                        this.on("removedfile", (file) => {
                          handleSelectFormData("file", null, setFileForm);
                        });
                      },
                    }} />
                  </SoftBox>
                </SoftBox>
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <SoftButton onClick={handleLessonContentSubmit} variant="gradient" color="info" size="small">
                Add Lesson Content
              </SoftButton>
            </Box>
            <Box mt={10}>
              <Table columns={LESSON_CONTENT_COLUMNS} rows={lessonContentRows} />
            </Box>
          </Box>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  )
}

const ARCHIVE_SELECT = [
  { label: "SELECT", value: true },
  { label: "UPLOAD", value: false }
]

const QUESTION_SELECT = [
  { label: "SELECT", value: true },
  { label: "CREATE", value: false }
]

const LESSON_CONTENT_COLUMNS = [
  { name: "title", align: "left" },
  { name: "type", align: "left" },
  { name: "content", align: "left" },
  { name: "action", align: "right" }
];

export const LESSON_CONTENT_SELECT = [
  { value: LESSON_CONTENT_ENUM.TEXT, label: "TEXT" },
  { value: LESSON_CONTENT_ENUM.VIDEO, label: "VIDEO" },
  { value: LESSON_CONTENT_ENUM.ARCHIVE, label: "ARCHIVE" },
  { value: LESSON_CONTENT_ENUM.QUESTION, label: "MULTIPLE CHOICE" }
]