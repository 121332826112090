import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { firestore } from "../configs/firebase";
import { environment } from "configs/environment";
import { Payment } from "models/Payment";

export class PaymentRepository {
  /**
   * Retrieves all payment from the Firestore collection.
   * @returns {Promise<Array<Payment>>} A Promise that resolves to an array of User objects.
   */
  async findAllPayment() {
    const response = await getDocs(query(collection(firestore, environment.COLLECTION.PAYMENTS), orderBy("createdDate", "desc")))
    const parseResponse = response.docs.map(document => new Payment({ id: document.id, ...document.data() }));
    console.log("findAllPayment", parseResponse);
    return parseResponse;
  }
}