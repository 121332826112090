import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DataTable from "components/examples/Tables/DataTable";
import { useContext, useEffect, useState } from "react";
import { Context } from "contexts/contextState";
import { COURSE_TABLE, CUSTOMER_COURSE_TABLE } from "views/constants/tables";
import { CourseActionCell } from "../components/CourseActionCell";
import { AddCustomerCourseModal } from "../components/AddCustomerCourseModal";
import SoftBadge from "components/SoftBadge";
import { Tooltip } from "@mui/material";
import { CompletePaymentPersonModal } from "../components/CompletePaymentPersonModal";
import { CertificationModal } from "../components/CertificationModal";
import { CERTIFICATION_TYPE, Certification } from "models/User";
import { ClassroomModal } from "../components/ClassroomModal";
import { ConsentsModal } from "../components/ConsentsModal";

export function EditCustomerCourses(props) {
  /****************** hooks ********************/
  const { context } = useContext(Context);
  const [firstPageLoader, setFirstPageLoader] = useState(true);
  const [courseTable, setCourseTable] = useState(CUSTOMER_COURSE_TABLE);
  const [addCustomerCourseModal, setAddCustomerCourseModal] = useState(false);
  const [completeCourseModal, setCompleteCourseModal] = useState();
  const [certificationModal, setCertificationModal] = useState();
  const [classroomModal, setClassroomModal] = useState();

  const [courseConsents, setCourseConsents] = useState([]);

  /****************** functions ********************/

  /**
   * @param {Certification} certification 
   */
  async function handleCertificationView(certification) {
    if (certification.type == CERTIFICATION_TYPE.MANUAL) {
      const certificationStorage = await context.fileStorageRepository.findFileStorageById(certification.documentId);
      window.open(certificationStorage.fileUrl, "_blank");
    } else {
      // TODO: need to implement certification automatic generate logic on here
    }
  }

  async function handlePageLoader() {
    const [courseResponse, imagesResponse] = await Promise.all([
      context.courseRepository.findAllCourse(),
      context.fileStorageRepository.findAllImages()
    ]);

    setCourseTable(previous => ({
      ...previous,
      rows: courseResponse.filter(course => props.customer.purchases?.includes(course.id) || props.customer.payInPersonPurchases?.includes(course.id)).map(course => ({
        ...course,
        product: [course.title, imagesResponse.find(image => image.id == course.thumbnailReference?.id)?.fileUrl],
        level: course.courseLevel?.label,
        price: course.price,
        active: course.active,
        // PAYMENT
        payment: props.customer.purchases.find(purchaseId => course.id == purchaseId) ?
          <SoftBadge variant="contained" color="success" size="xs" badgeContent="Completed" container /> :
          <Tooltip title="Complete payment"><SoftBadge onClick={() => setCompleteCourseModal(course)} style={{ cursor: "pointer" }} variant="contained" color="warning" size="xs" badgeContent="Pay In Person" container /></Tooltip>,
        // CLASSROOM
        classroom: !course.classroom ?
          <SoftBadge variant="contained" color="light" size="xs" badgeContent="Not Apply" container /> :
          props.customer.completedClassrooms?.includes(course.id) ?
            <SoftBadge style={{ cursor: "pointer" }} variant="contained" color="info" size="xs" badgeContent="Completed" container /> :
            <Tooltip title="Customer finished this class ?"><SoftBadge onClick={() => setClassroomModal(course)} style={{ cursor: "pointer" }} variant="contained" color="secondary" size="xs" badgeContent="Finish Classroom" container /></Tooltip>,
        // CERTIFICATION
        certification: props.customer.certifications.find(certification => certification.courseId == course.id) ?
          <SoftBadge onClick={() => handleCertificationView(props.customer.certifications.find(certification => certification.courseId == course.id))} style={{ cursor: "pointer" }} variant="contained" color="info" size="xs" badgeContent="Certification" container /> :
          props.customer.completedCourses.find(completedCourse => completedCourse.courseId == course.id) ?
            <Tooltip title="Upload Certification"><SoftBadge onClick={() => setCertificationModal(course)} style={{ cursor: "pointer" }} variant="contained" color="dark" size="xs" badgeContent="Upload" container /></Tooltip> :
            <Tooltip title="Course need to be completed"><SoftBadge variant="contained" color="light" size="xs" badgeContent="in progress" container /></Tooltip>,
        // CONSENT
        consent: props.customer.consents.find(consent => consent.courseId == course.id) ?
          <SoftBadge onClick={() => setCourseConsents(props.customer.consents.filter(consent => consent.courseId == course.id))} style={{ cursor: "pointer" }} variant="contained" color="info" size="xs" badgeContent="Available" container /> :
          <SoftBadge variant="contained" color="light" size="xs" badgeContent="Not Send" container />,
        action: <CourseActionCell course={course} customer={props.customer} />,
      }))
    }));
  }

  /****************** useEffects ********************/

  useEffect(() => {
    // it will just execute once
    if (firstPageLoader) {
      setFirstPageLoader(false);
      // codding ...
      handlePageLoader();
    }
  }, []);

  return (
    <Card>
      <ConsentsModal open={Boolean(courseConsents.length)} consents={courseConsents} onClose={() => setCourseConsents([])} />
      <ClassroomModal open={Boolean(classroomModal)} customer={props.customer} course={classroomModal} onClose={() => setClassroomModal(void 0)} />
      <CertificationModal open={Boolean(certificationModal)} customer={props.customer} course={certificationModal} onClose={() => setCertificationModal(void 0)} />
      <CompletePaymentPersonModal open={Boolean(completeCourseModal)} onClose={() => setCompleteCourseModal(void 0)} customer={props.customer} course={completeCourseModal} />
      <AddCustomerCourseModal open={addCustomerCourseModal} customer={props.customer} onClose={() => setAddCustomerCourseModal(false)} />
      <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
        <SoftBox lineHeight={1}>
          <SoftTypography variant="h5" fontWeight="medium">
            All Customer Courses
          </SoftTypography>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            Comprehensive catalog of customer courses
          </SoftTypography>
        </SoftBox>
        <Stack spacing={1} direction="row">
          <SoftButton variant="gradient" color="info" size="small" onClick={() => setAddCustomerCourseModal(true)}>
            + new course
          </SoftButton>
        </Stack>
      </SoftBox>
      <DataTable
        canSearch
        table={courseTable}
        entriesPerPage={{
          defaultValue: 25,
          entries: [10, 25, 50, 100],
        }}
      />
    </Card>
  );
}

EditCustomerCourses.propTypes = {
  customer: PropTypes.object.isRequired,
  setCustomer: PropTypes.func.isRequired
}