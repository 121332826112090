/**
=========================================================
* Hydra Security Admin - v4.0.0
=========================================================

* Product Page: https://codejays.com//product/soft-ui-dashboard-pro-react
* Copyright 2022 Code Jays (https://codejays.com/)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Hydra Security Admin helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const step = {
  styleOverrides: {
    root: {
      padding: `0 ${pxToRem(6)}`,
    },
  },
};

export default step;
