export class User {
  constructor(entity) {
    this.id = entity?.id || null;
    this.active = entity?.active || false;
    this.HSId = entity?.HSId || null;
    this.address = entity?.address || null;
    this.city = entity?.city || null;
    this.postalCode = entity?.postalCode || null;
    this.province = entity?.province || null;
    this.firstName = entity?.firstName || "";
    this.lastName = entity?.lastName || "";
    this.fileUploaded = entity?.fileUploaded || false;
    this.password = entity?.password || null;
    this.phone = entity?.phone || null;
    this.email = entity?.email || null;
    /** @type {ACCOUNT_PERMISSION} */
    this.permission = entity?.permission || ACCOUNT_PERMISSION;
    /** @type {Array<string>} - course id */
    this.purchases = entity?.purchases || [];
    /** @type {Array<string>} - courses waiting payment in person */
    this.payInPersonPurchases = entity?.payInPersonPurchases || [];
    /** @type {Array<CompletedCourse>} */
    this.completedCourses = entity?.completedCourses?.map(completedCourse => new CompletedCourse(completedCourse)) || [];
    /** @type {Array<string>} - course id */
    this.completedClassrooms = entity?.completedClassrooms || [];
    /** @type {Array<Certification>} - course id */
    this.certifications = entity?.certifications?.map(certification => new Certification(certification)) || [];
    /** @type {Array<Consent>} - course id */
    this.consents = entity?.consents?.map(consent => new Consent(consent)) || [];
    this.imageProfileReference = entity?.imageProfileReference || null;
    this.dob = entity?.dob instanceof Date ? entity?.dob : entity?.dob?.toDate() || null;
    this.createdDate = entity?.createdDate instanceof Date ? entity?.createdDate : entity?.createdDate?.toDate() || null;
    this.updatedDate = entity?.updatedDate instanceof Date ? entity?.updatedDate : entity?.updatedDate?.toDate() || null;
  }
}

export class Consent {
  constructor(entity) {
    this.id = entity?.id || Date.now();
    this.courseId = entity?.courseId || null;
    this.documentId = entity?.documentId || null;
    this.createdDate = entity?.createdDate instanceof Date ? entity?.createdDate : entity?.createdDate?.toDate() || null;
  }
}

export class CompletedCourse {
  constructor(entity) {
    this.id = entity?.id || Date.now();
    this.courseId = entity?.courseId || null;
    this.createdDate = entity?.createdDate instanceof Date ? entity?.createdDate : entity?.createdDate?.toDate() || null;
  }
}

export class Certification {
  constructor(entity) {
    this.id = entity?.id || Date.now(); // id can be use to know when the certification was generated
    /** @type {CERTIFICATION_TYPE.AUTOMATIC || CERTIFICATION_TYPE.MANUAL} */
    this.type = entity?.type || CERTIFICATION_TYPE.AUTOMATIC;
    this.courseId = entity?.courseId || null;
    this.documentId = entity?.documentId || null;
  }
}

export const CERTIFICATION_TYPE = {
  MANUAL: "MANUAL",
  AUTOMATIC: "AUTOMATIC"
}

export const ACCOUNT_PERMISSION = {
  root: false,
  admin: false,
  customer: {
    view: false,
    update: false,
    delete: false
  },
  course: {
    view: false,
    update: false,
    delete: false
  },
  question: {
    view: false,
    update: false,
    delete: false
  },
  payment: {
    view: false,
    update: false,
    delete: false
  }
}


export const OWNER_PERMISSION = {
  root: true,
  admin: true,
  customer: {
    view: true,
    update: true,
    delete: true
  },
  course: {
    view: true,
    update: true,
    delete: true
  },
  question: {
    view: true,
    update: true,
    delete: true
  },
  payment: {
    view: true,
    update: true,
    delete: true
  }
}