import { environment } from "configs/environment";
import { http } from "./configs/axios";

export class FileStorageService {
  async videoUpload(file, onUploadProgress) {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const headers = {
        "Authorization": `Bearer ${localStorage.getItem("firebaseToken")}`,
        "Content-Type": "multipart/form-data"
      };
      const response = await http.post(environment.API.ENDPOINTS.VIDEO_UPLOAD, formData, { 
        headers, 
        onUploadProgress: onUploadProgress ? onUploadProgress : (event) => {
          const percent = Math.floor(event.loaded / event.total * 100);
          console.log(percent);
        } 
      });
      console.log('Upload successful:', response);
      return response?.data;
    } catch (error) {
      console.error('Error uploading video:', error);
    }
  }

  async imageUpload(file, onUploadProgress) {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const headers = {
        "Authorization": `Bearer ${localStorage.getItem("firebaseToken")}`,
        "Content-Type": "multipart/form-data"
      };
      const response = await http.post(environment.API.ENDPOINTS.IMAGE_UPLOAD, formData, { 
        headers, 
        onUploadProgress: onUploadProgress ? onUploadProgress : (event) => {
          const percent = Math.floor(event.loaded / event.total * 100);
          console.log(percent);
        } 
      });
      console.log('Upload successful:', response);
      return response?.data;
    } catch (error) {
      console.error('Error uploading video:', error);
    }
  }
}