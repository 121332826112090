import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { VideoPreviewModal } from "./VideoPreviewModal";
import { useContext, useState } from "react";
import { DeleteLessonContentModal } from "./DeleteLessonContentModal";
import { EditLessonContentModal } from "./EditLessonContentModal";
import { LESSON_CONTENT_ENUM } from "models/Lesson";
import { useNavigate } from "react-router-dom";
import { Context } from "contexts/contextState";

export function LessonContentActionCell(props) {
  const navigate = useNavigate();
  const { context } = useContext(Context);
  const [showVideoPreview, setShowVideoPreview] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  function handleModalClose() {
    setShowVideoPreview(false);
    setShowDeleteModal(false);
    setShowEditModal(false);
    props.onRefresh?.();
  }

  return (
    <SoftBox display="flex" alignItems="center">
      <VideoPreviewModal open={showVideoPreview} onClose={handleModalClose} lessonContent={props.lessonContent} />
      <EditLessonContentModal archivesSelector={props.archivesSelector} questionsSelect={props.questionsSelect} open={showEditModal} onClose={handleModalClose} course={props.course} lesson={props.lesson} lessonContent={props.lessonContent} />
      <DeleteLessonContentModal open={showDeleteModal} onClose={handleModalClose} course={props.course} lesson={props.lesson} lessonContent={props.lessonContent} />
      <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} onClick={() => {
        if (props.lessonContent.type == LESSON_CONTENT_ENUM.VIDEO) {
          setShowVideoPreview(true);
        } else if (props.lessonContent.type == LESSON_CONTENT_ENUM.ARCHIVE) {
          context.fileStorageRepository.findFileStorageById(props.lessonContent.archiveId).then(response => {
            window.open(response.fileUrl, "_blank");
          });
        } else if (props.lessonContent.type == LESSON_CONTENT_ENUM.QUESTION) {
          navigate("/admin/questions/all/edit/" + props.lessonContent.questionId);
        }
      }}>
        <Tooltip title="Preview Lesson Content" placement="top">
          <Icon>visibility</Icon>
        </Tooltip>
      </SoftTypography>
      <SoftBox mx={2}>
        <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} onClick={() => setShowEditModal(true)}>
          <Tooltip title="Edit Lesson Content" placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
      <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} onClick={() => setShowDeleteModal(true)}>
        <Tooltip title="Delete Lesson Content" placement="left">
          <Icon>delete</Icon>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
}

LessonContentActionCell.propTypes = {
  course: PropTypes.any,
  lesson: PropTypes.any,
  lessonContent: PropTypes.any,
  questionsSelect: PropTypes.any,
  archivesSelector: PropTypes.any,
  onRefresh: PropTypes.func
};