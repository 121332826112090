import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { Context } from "contexts/contextState";
import { Course } from "models/Course";
import { Lesson } from "models/Lesson";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { errorAlert, successAlert } from "views/components/Alerts";

export function DeleteLessonModal(props) {
  const { context } = useContext(Context);
  const [processing, setProcessing] = useState(false);

  async function handleDeleteLesson() {
    setProcessing(true);
    try {
      const course = new Course(props.course);
      const deletedLesson = new Lesson(props.lesson);
      course.lessons = course.lessons.filter(lesson => !(lesson.id == deletedLesson.id));
      await context.courseRepository.save(course);
      successAlert("Success!", "The lesson has been deleted.").then(() => {
        window.location.reload();
      });
      props.onClose();
    } catch (error) {
      console.log("handleDeleteLesson", error);
      errorAlert("Error!", "Failed to delete a lesson in our database. Please check your connection and try again. If the issue persists, please contact our support team for assistance.");
    } finally {
      setProcessing(false);
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>
        Are you certain you want to delete this lesson?
      </DialogTitle>
      <DialogContent>
        <SoftTypography variant="body2">
          If you delete this lesson <b>{props.lesson?.title || ""}</b>, it cannot be recovered. <br />Are you sure you want to proceed?
        </SoftTypography>
        <br />
        <SoftTypography variant="body2">
          Click the <b>Delete</b> button to confirm or <b>Cancel</b> to go back.
        </SoftTypography>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flex="1">
          <SoftButton variant="text" color="error"
            onClick={handleDeleteLesson}
            disabled={!(context.permission.root || context.permission.course.delete)}
          >
            Delete
          </SoftButton>
          <SoftButton variant="text" color="info" onClick={props.onClose} disabled={processing}>Cancel</SoftButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

DeleteLessonModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  lesson: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
};