import { Box, Card, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "components/examples/Footer";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import { useContext, useEffect, useState } from "react";
import SoftButton from "components/SoftButton";
import { Context } from "contexts/contextState";
import { Question } from "models/Question";
import { MainHeader } from "views/components/MainHeader";
import { errorAlert } from "views/components/Alerts";
import { successAlert } from "views/components/Alerts";
import { useNavigate, useParams } from "react-router-dom";
import SoftSelect from "components/SoftSelect";
import { Answer } from "models/Answer";
import FormField from "components/layouts/applications/wizard/components/FormField";
import { handleFormData, handleSelectFormData } from "services/HandleService";
import Table from "components/examples/Tables/Table";
import SoftBadge from "components/SoftBadge";
import { AnswerActionCell } from "../create/components/AnswerActionCell";


// use this component to create new pages view
export function EditQuestionView(props) {
  /****************** hooks ********************/
  const params = useParams();
  const navigate = useNavigate();
  const { context } = useContext(Context);
  const [questionForm, setQuestionForm] = useState(new Question());
  const [answerForm, setAnswerForm] = useState(new Answer());

  /****************** functions ********************/

  function handleAddAnswer() {
    const question = new Question(questionForm)
    if (!question.title || !answerForm.title) {
      return errorAlert("Error!", "Failed to create a new answer. Please make sure that the answer field is not empty and 'Is it correct answer' is selected");
    }
    question.answers.push(answerForm);
    setQuestionForm(question);
    setAnswerForm(new Answer());
    successAlert("Success!", "The new answer has been successfully added.");
  }

  function handleRemoveAnswerFromQuestion(answer) {
    const question = new Question(questionForm);
    question.answers = question.answers.filter(currentAnswer => currentAnswer.id != answer.id);
    setQuestionForm(question);
  }

  async function handleCreateQuestionSubmit() {
    const question = new Question(questionForm);
    if (!question.title || !question.answers.length) {
      return errorAlert("Error!", "Failed to create a new question block. Please make sure that the question field is not empty and at least one answer is added to the answers list");
    }
    try {
      const responseQuestion = await context.questionRepository.save(question);
      console.log("handleCreateQuestionSubmit", responseQuestion);
      successAlert("Success!", "The new question block has been successfully updated.");
    } catch (error) {
      console.log("handleCreateCourseSubmit", error);
      errorAlert("Error!", "Failed to create a new question block in our database. Please check your connection and try again. If the issue persists, please contact our support team for assistance.");
    }
  }

  useEffect(() => {
    if (params.id) {
      context.questionRepository.findQuestionById(params.id).then(response => setQuestionForm(response));
    }
  }, []);

  return (
    <DashboardLayout>
      <MainHeader />
      <SoftBox my={3}>
        <Card style={{ overflow: "visible" }}>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Edit New Multiple Choice
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Please complete all the fields below to update a new multiple choice block.
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <Box p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormField type="text" label="Question:" name="title" value={questionForm.title} placeholder="Please write here your question" onChange={(event) => handleFormData(event, setQuestionForm)} />
              </Grid>
            </Grid>
            <Box mt="15px" />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField type="text" label="Answer:" name="title" value={answerForm.title} placeholder="Please write here your answer for the question above" onChange={(event) => handleFormData(event, setAnswerForm)} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box>
                  <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                    Is it correct answer:
                  </SoftTypography>
                  <RadioGroup defaultValue="NO" onChange={value => handleSelectFormData("isCorrectAnswer", value.target.value == "YES", setAnswerForm)}>
                    <Box display="flex" gap={8} mt={0.5} ml={2}>
                      <FormControlLabel value="YES" control={<Radio />} label="YES" />
                      <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    </Box>
                  </RadioGroup>
                </Box>
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="end" py="20px">
              <SoftButton variant="outlined" color="info" size="small"
                onClick={handleAddAnswer}
                disabled={!(context.permission.root || context.permission.question.update)}
              >
                Add Answer
              </SoftButton>
            </Box>
            <SoftBox mt={1}>
              <Table columns={ANSWER_TABLE_COLUMNS} rows={questionForm.answers.map(answer => ({
                title: ["", <SoftTypography variant="p" color="inherit">{answer.title}</SoftTypography>],
                correct: answer.isCorrectAnswer ? <SoftBadge variant="contained" color="success" size="xs" badgeContent="YES" container /> : <SoftBadge variant="contained" color="error" size="xs" badgeContent="NO" container />,
                action: <AnswerActionCell answer={answer} onDelete={handleRemoveAnswerFromQuestion} />
              }))} />
            </SoftBox>
            <Box display="flex" justifyContent="end" py="20px" mt="40px">
              <SoftButton variant="gradient" color="info" size="small"
                onClick={handleCreateQuestionSubmit}
                disabled={!(context.permission.root || context.permission.question.update)}
              >
                Update Multiple Choice
              </SoftButton>
            </Box>
          </Box>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  )
}

const ANSWER_TABLE_COLUMNS = [
  { name: "title", align: "left" },
  { name: "correct", align: "center" },
  { name: "action", align: "right" }
];