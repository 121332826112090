import { Card } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "components/examples/Footer";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import { useContext, useEffect, useState } from "react";
import { MainHeader } from "../../components/MainHeader";
import { CUSTOMER_DATA_TABLE } from "../../constants/tables";
import { Context } from "contexts/contextState";
import DataTable from "components/examples/Tables/DataTable";
import { CustomerActionCell } from "./components/table/CustomerActionCell";


export function CustomersView(props) {
  /****************** hooks ********************/
  const { context } = useContext(Context);
  const [firstPageLoader, setFirstPageLoader] = useState(true);
  const [customerDataTable, setCustomerDataTable] = useState(CUSTOMER_DATA_TABLE);

  /****************** functions ********************/

  async function handlePageLoader() {
    const [customersResponse, imagesResponse] = await Promise.all([
      context.userRepository.findAllUser(),
      context.fileStorageRepository.findAllImages()
    ]);
    const rows = customersResponse.map(customer => ({
      // name, image, email, createdDate
      name: [
        (customer.firstName || "") + " " + (customer.lastName || ""),
        imagesResponse.find(image => image.id == customer.imageProfileReference?.id)?.fileUrl || "",
        customer.email,
        customer.createdDate
      ],
      phone: context.formatterService.formatPhoneNumber(customer.phone),
      payment: Boolean(customer.payInPersonPurchases?.length) ? "waiting" : Boolean(customer.purchases?.length) ? "paid" : "unpaid",
      active: customer.active,
      joined: context.formatterService.formatDate(customer.createdDate),
      action: (<CustomerActionCell customer={customer} />)
    }));
    setCustomerDataTable(previous => ({ ...previous, rows }));
  }

  /****************** useEffects ********************/

  useEffect(() => {
    if (firstPageLoader) {
      setFirstPageLoader(false);
      handlePageLoader();
    }
  }, []);

  return (
    <DashboardLayout>
      <MainHeader />
      <SoftBox my={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Customers
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                List of all registered customers on the website.
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox py={3}>
            <DataTable
              table={customerDataTable}
              canSearch
              entriesPerPage={{
                defaultValue: 25,
                entries: [10, 25, 50, 100],
              }}
            />
          </SoftBox>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  )
}