import { Card, Stack, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "components/examples/Footer";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import { useContext, useEffect, useState } from "react"
import { MainHeader } from "../../components/MainHeader";
import { Context } from "contexts/contextState";
import DataTable from "components/examples/Tables/DataTable";
import { PAYMENT_LEGACY_TABLE } from "./components/table/table";

// firebase 

import { collection, getDocs, query } from "firebase/firestore"
import { firestore } from "services/firebase/configs/firebase";


// use this component to create new pages view
export function PaymentsLegacyView(props) {
  /****************** hooks ********************/
  const { context } = useContext(Context);

  // states
  const [paymentTable, setPaymentTable] = useState(PAYMENT_LEGACY_TABLE);

  /****************** functions ********************/

  async function handlePageLoader() {
    const [userDatabase] = await Promise.all([context.userRepository.findAllUser()]);
    const userDatabaseParseMap = new Map(userDatabase.map(customer => [customer.id, customer]));

    const enrollResponse = await getDocs(query(collection(firestore, "enrollment"))).then(response => response.docs.map(document => ({ id: document.id, ...document.data() })));
    const productsResponse = await getDocs(query(collection(firestore, "products"))).then(response => response.docs.map(document => ({ id: document.id, ...document.data() })));

    setPaymentTable(previous => ({
      ...previous,
      rows: enrollResponse.map(enroll => {
        const customer = userDatabaseParseMap.get(enroll.userId);
        const product = productsResponse.find(product => product.id == enroll.productId)
        return ({
          id: enroll.paymentId || enroll.payment_method,
          date: context.formatterService.formatDate(enroll.createdAt?.toDate()),
          status: enroll.courses.find(_ => true)?.status || "not enrolled",
          customer: [customer, { image: "" }],
          product: product?.title || "",
          revenue: context.formatterService.formatCurrency("CAD", product?.price || 0),
        })
      })
    }))
  }

  /****************** useEffects ********************/

  useEffect(() => {
    handlePageLoader();
  }, []);

  return (
    <DashboardLayout>
      <MainHeader />
      <SoftBox my={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Payments Legacy
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                List of payments made by customers.
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction="row">

            </Stack>
          </SoftBox>
          <DataTable
            canSearch
            table={paymentTable}
            entriesPerPage={{
              defaultValue: 25,
              entries: [10, 25, 50, 100],
            }}
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  )
}