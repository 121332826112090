/** 
  All of the routes for the Hydra Security Admin are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Hydra Security Admin layouts
import ResetIllustration from "components/layouts/authentication/reset-password/illustration";

// Hydra Security Admin icons
import Document from "components/examples/Icons/Document";
import { LoginView } from "views/LoginView";
import { Videocam, QuestionAnswer, Payment, Money, CurrencyExchange } from "@mui/icons-material";
import CustomerSupport from "components/examples/Icons/CustomerSupport";
import { CreateCourseView } from "views/courses/create/CreateCourseView";
import { CoursesView } from "views/courses/all/CoursesView";
import { QuestionsView } from "views/questions/all/QuestionsView";
import { CustomersView } from "views/customers/all/CustomersView";
import { EditCourseView } from "views/courses/edit/EditCourseView";
import { Navigate } from "react-router-dom";
import { EditCustomerView } from "views/customers/edit/EditCustomerView";
import NewUser from "components/layouts/pages/users/new-user";
import { LessonView } from "views/courses/all/lessons/LessonView";
import { CreateQuestionView } from "views/questions/create/CreateQuestionView";
import { EditQuestionView } from "views/questions/edit/EditQuestionView";
import { PaymentsView } from "views/payments/all/PaymentsView";
import { PaymentsLegacyView } from "views/payments/legacy/PaymentsLegacyView";

const routes = [
  // {
  //   // type: "collapse",
  //   name: "Dashboards",
  //   key: "dashboards",
  //   icon: <Dashboard size="12px" />,
  //   collapse: [
  //     {
  //       name: "Default",
  //       key: "default",
  //       route: "/admin/dashboard",
  //       component: <Default />,
  //     },
  //   ],
  // },
  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    icon: <CustomerSupport size="12px" />,
    collapse: [
      // this router is to breadcrumb know where it should be redirected
      {
        name: "View Customers",
        key: "all-redirect",
        route: "/admin/customers",
        component: <Navigate to="/admin/customers/all" />,
        hidden: true
      },
      {
        name: "View Customers",
        key: "all",
        route: "/admin/customers/all",
        component: <CustomersView />,
      },
      { // router to fix breadcrumb
        name: "Edit Customers",
        key: "all",
        route: "/admin/customers/all/edit",
        component: <Navigate to="/admin/customers/all" />,
        hidden: true
      },
      {
        name: "Edit Customers",
        key: "all",
        route: "/admin/customers/all/edit/:id",
        component: <EditCustomerView />,
        hidden: true
      }
    ],
  },
  {
    type: "collapse",
    name: "Courses",
    key: "courses",
    icon: <Videocam size="12px" />,
    collapse: [
      {
        name: "Create Course",
        key: "create",
        route: "/admin/courses/create",
        component: <CreateCourseView />,
      },
      {
        name: "View Courses",
        key: "all-redirect",
        route: "/admin/courses",
        component: <Navigate to="/admin/courses/all" />,
        hidden: true
      },
      {
        name: "View Courses",
        key: "all",
        route: "/admin/courses/all",
        component: <CoursesView />,
      },
      { // router to fix breadcrumb
        name: "Edit Courses",
        key: "edit",
        route: "/admin/courses/all/edit",
        component: <Navigate to="/admin/courses/all" />,
        hidden: true
      },
      {
        name: "Edit Courses",
        key: "edit",
        route: "/admin/courses/all/edit/:id",
        component: <EditCourseView />,
        hidden: true
      },
      {
        name: "Edit Lesson",
        key: "lesson",
        route: "/admin/courses/all/edit/:courseId/lesson/:lessonId",
        component: <LessonView />,
        hidden: true
      },
    ],
  },
    {
    type: "collapse",
    name: "Multiple Choice",
    key: "questions",
    icon: <QuestionAnswer size="12px" />,
    collapse: [
      {
        name: "Create Multiple Choice",
        key: "create",
        route: "/admin/questions/create",
        component: <CreateQuestionView />,
      },
      {
        name: "View Multiple Choice",
        key: "all-redirect",
        route: "/admin/questions",
        component: <Navigate to="/admin/questions/all" />,
        hidden: true
      },
      {
        name: "View Multiple Choice",
        key: "all",
        route: "/admin/questions/all",
        component: <QuestionsView />,
      },
      { // router to fix breadcrumb
        name: "View Multiple Choice",
        key: "all-redirect",
        route: "/admin/questions/all/edit",
        component: <Navigate to="/admin/questions/all" />,
        hidden: true
      },
      {
        name: "Edit Multiple Choice",
        key: "edit",
        route: "/admin/questions/all/edit/:id",
        component: <EditQuestionView />,
        hidden: true
      },
    ],
  },
  {
    type: "collapse",
    name: "Payments",
    key: "payments",
    icon: <CurrencyExchange size="12px" />,
    collapse: [
      {
        name: "View Payments",
        key: "all-redirect",
        route: "/admin/payments",
        component: <Navigate to="/admin/payments/all" />,
        hidden: true
      },
      {
        name: "View Payments",
        key: "all",
        route: "/admin/payments/all",
        component: <PaymentsView />,
      },
      {
        name: "Payments Legacy",
        key: "legacy",
        route: "/admin/payments/legacy",
        component: <PaymentsLegacyView />,
      }
    ],
  },
  {
    // type: "collapse",
    name: "Authentication",
    key: "authentication",
    icon: <Document size="12px" />,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/sign-in",
            component: <LoginView />,
          },
        ],
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/reset-password",
            component: <ResetIllustration />,
          },
        ],
      }
    ],
  },
];

export default routes;
