import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { useContext, useEffect, useState } from "react";
import { Context } from "contexts/contextState";
import { UploadThumbnailModal } from "../components/UploadThumbnailModal";
import { DeleteThumbnailModal } from "../components/DeleteThumbnailModal";
import hero from "assets/images/hero.png";

export function CourseThumbnail(props) {
  const { context } = useContext(Context);
  const [thumbnail, setThumbnail] = useState("");
  const [deleteThumbnailModal, setDeleteThumbnailModal] = useState();
  const [updateThumbnailModal, setUpdateThumbnailModal] = useState();

  useEffect(() => {
    if (props.course) {
      context.fileStorageRepository.findFileStorageById(props.course.thumbnailReference?.id).then(response => {
        setThumbnail(response?.fileUrl || hero);
      });
    }
  }, [props.course]);

  return (
    <Card sx={{ height: "100%" }}>
      <DeleteThumbnailModal course={props.course} open={Boolean(deleteThumbnailModal)} onClose={() => setDeleteThumbnailModal(void 0)} />
      <UploadThumbnailModal course={props.course} open={Boolean(updateThumbnailModal)} onClose={() => setUpdateThumbnailModal(void 0)} />
      <SoftBox p={3}>
        <SoftTypography variant="h5" fontWeight="bold">
          Thumbnail
        </SoftTypography>
        <SoftBox display="flex" justifyContent="center">
          <SoftBox component="img" src={thumbnail} borderRadius="lg" shadow="lg" width="360px" maxHeight="420px" my={3} />
        </SoftBox>
        <SoftBox display="flex" justifyContent="space-between">
          <SoftButton variant="outlined" color="dark" size="small"
            onClick={() => setDeleteThumbnailModal(true)}
            disabled={!(context.permission.root || context.permission.course.update)}
          >
            remove
          </SoftButton>
          <SoftButton variant="gradient" color="info" size="small"
            onClick={() => setUpdateThumbnailModal(true)}
            disabled={!(context.permission.root || context.permission.course.update)}
          >
            update
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </Card>
  )
}

CourseThumbnail.propTypes = {
  course: PropTypes.object.isRequired,
  setCourse: PropTypes.func.isRequired
}