import { Card } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "components/examples/Footer";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import { useContext, useEffect, useState } from "react";
import { MainHeader } from "../../components/MainHeader";

import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import { Context } from "contexts/contextState";
import { CourseThumbnail } from "./fragments/CourseThumbnail";
import { EditProductInfo } from "./fragments/EditProductInfo";
import { ClassLesson } from "./fragments/ClassLesson";


export function EditCourseView(props) {
  const params = useParams();
  const { context } = useContext(Context);
  const [firstPageLoader, setFirstPageLoader] = useState(true);
  const [course, setCourse] = useState({});

  async function handlePageLoader() {
    const courseResponse = await context.courseRepository.findCourseById(params.id);
    setCourse(courseResponse);
  }

  useEffect(() => {
    if (firstPageLoader) {
      setFirstPageLoader(false);
      handlePageLoader();
    }
  }, []);

  return (
    <DashboardLayout>
      <MainHeader />
      <SoftBox my={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Edit Course
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                {course.title}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <CourseThumbnail course={course} setCourse={setCourse} />
            </Grid>
            <Grid item xs={12} lg={8}>
              <EditProductInfo course={course} setCourse={setCourse} />
            </Grid>
          </Grid>
          <SoftBox mt={3}>
            <ClassLesson course={course} setCourse={setCourse} />
          </SoftBox>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  )
}