import { Box, Dialog, DialogActions, DialogContent, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import FormField from "components/layouts/applications/wizard/components/FormField";
import { Context } from "contexts/contextState";
import { Course } from "models/Course";
import { LESSON_STATUS, Lesson } from "models/Lesson";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { errorAlert, successAlert } from "views/components/Alerts";
import { handleFormData, handleSelectFormData } from "services/HandleService";

export function EditLessonModal(props) {
  const { context } = useContext(Context);
  const [formLesson, setFormLesson] = useState(new Lesson(props.lesson));
  const [processing, setProcessing] = useState(false);

  async function handleSaveLesson() {
    setProcessing(true);
    try {
      console.log(formLesson);
      const course = new Course(props.course);
      formLesson.updatedDate = new Date();
      course.lessons = course.lessons.map(lesson => lesson.id == formLesson.id ? formLesson : lesson);
      await context.courseRepository.save(course);
      successAlert("Success!", "The edits made to your lesson have been successfully updated in the database.").then(() => {
        window.location.reload();
      });
      props.onClose();
    } catch (error) {
      console.log(error);
      errorAlert("Error!", "Failed to update a lesson in our database. Please check your connection and try again. If the issue persists, please contact our support team for assistance.");
    } finally {
      setProcessing(false);
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} sx={{ overflow: "visible" }} fullWidth>
      <SoftBox mx="16px" mt="16px">
        <SoftTypography variant="h5">Lesson Information</SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          Edit all information about {props.lesson.title}
        </SoftTypography>
      </SoftBox>
      <DialogContent>
        <SoftBox display="flex" flex="1" flexDirection="column" mt={1}>
          <FormField type="text" label="title" name="title" value={formLesson.title || ""} placeholder="eg. Oreo Overloaded" onChange={(event) => handleFormData(event, setFormLesson)} />
          <SoftBox mt={1}>
            <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
              Status:
            </SoftTypography>
            <RadioGroup value={formLesson.active ? "ACTIVE" : "DISABLED"} onChange={value => handleSelectFormData("active", value.target.value == "ACTIVE", setFormLesson)}>
              <Box display="flex" gap={8} mt={0.5} ml={2}>
                <FormControlLabel value="ACTIVE" control={<Radio />} label="Active" />
                <FormControlLabel value="DISABLED" control={<Radio />} label="Disabled" />
              </Box>
            </RadioGroup>
          </SoftBox>
        </SoftBox>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flex="1">
          <SoftButton variant="text" color="info" onClick={props.onClose} disabled={processing}>Cancel</SoftButton>
          <SoftButton variant="text" color="info"
            onClick={handleSaveLesson}
            disabled={!(context.permission.root || context.permission.course.update)}
          >
            Confirm
          </SoftButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

EditLessonModal.propTypes = {
  course: PropTypes.object.isRequired,
  lesson: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}