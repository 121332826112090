
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { FileStorage, STORAGE_TYPE } from "models/FileStorage";
import { firestore, storage } from "../configs/firebase";
import { environment } from "configs/environment";
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

export class FileStorageRepository {
  /**
   * Retrieve a file data from firestore collection
   * 
   * @param {string} documentId 
   * @returns {Promise<FileStorage>} 
   */
  async findFileStorageById(documentId) {
    if (documentId) {
      const response = await getDoc(doc(firestore, environment.COLLECTION.FILE_STORAGES, documentId));
      if (response.exists()) {
        const fileParse = new FileStorage({ id: response.id, ...response.data() });
        console.log("findFileById", fileParse);
        return fileParse;
      }
    }
    return null;
  }

  /**
   * Retrieves all image from the Firestore collection.
   * @param {STORAGE_TYPE} storageType
   * @returns {Promise<Array<FileStorage>>} A Promise that resolves to an array of FileStorage objects.
   */
  async findAllFileByType(storageType) {
    const response = await getDocs(
      query(collection(firestore, environment.COLLECTION.FILE_STORAGES), where("type", "==", storageType))
    );
    return response.docs.map(document => new FileStorage({ id: document.id, ...document.data() }));
  }

  /**
   * Retrieves all image from the Firestore collection.
   * @returns {Promise<Array<FileStorage>>} A Promise that resolves to an array of FileStorage objects.
   */
  async findAllImages() {
    const response = await getDocs(
      query(collection(firestore, environment.COLLECTION.FILE_STORAGES), where("type", "==", STORAGE_TYPE.IMAGE))
    )
    const parseResponse = response.docs.map(document => new FileStorage({ id: document.id, ...document.data() }));
    console.log("findAllImages", parseResponse);
    return parseResponse;
  }

  /**
   * Retrieves all video from the Firestore collection.
   * @returns {Promise<Array<FileStorage>>} A Promise that resolves to an array of User objects.
   */
  async findAllVideos() {
    const response = await getDocs(
      query(collection(firestore, environment.COLLECTION.FILE_STORAGES), where("type", "==", STORAGE_TYPE.VIDEO))
    )
    const parseResponse = response.docs.map(document => new FileStorage({ id: document.id, ...document.data() }));
    console.log("findAllVideos", parseResponse);
    return parseResponse;
  }

  /**
   * Retrieves all archive from the Firestore collection.
   * @returns {Promise<Array<FileStorage>>} A Promise that resolves to an array of User objects.
   */
  async findAllArchives() {
    const response = await getDocs(
      query(collection(firestore, environment.COLLECTION.FILE_STORAGES), where("type", "==", STORAGE_TYPE.ARCHIVE))
    )
    const parseResponse = response.docs.map(document => new FileStorage({ id: document.id, ...document.data() }));
    console.log("findAllArchives", parseResponse);
    return parseResponse;
  }

  /**
   * 
   * @param {File} file 
   * @param {STORAGE_TYPE} storageType 
   * @param {Function} callback function(progressPercent, snapshot)
   * @returns {Promise<FileStorage>}
   */
  async uploadFile(file, storageType, callback) {
    const filePathReference = environment.COLLECTION.FILE_STORAGES + "/" + storageType + "/" + Date.now() + file.name;
    const storageReference = ref(storage, filePathReference);
    const uploadTask = uploadBytesResumable(storageReference, file);

    const handleUploadProgress = (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      if (callback) {
        callback(progress, snapshot);
      }
    }

    return new Promise((resolve, reject) => {
      uploadTask.on("state_changed", handleUploadProgress, reject, async () => {
        // object creation
        const fileStorage = new FileStorage();
        fileStorage.fileName = file.name;
        fileStorage.type = storageType || STORAGE_TYPE.ARCHIVE;
        fileStorage.filePath = filePathReference;
        fileStorage.fileUrl = await getDownloadURL(storageReference);
        fileStorage.fileSize = file.size;
        fileStorage.createdDate = new Date();
        try {
          // save in collection
          const referenceCollection = collection(firestore, environment.COLLECTION.FILE_STORAGES);
          const saveDocument = { ...fileStorage };
          delete saveDocument.id;
          const documentCollection = await addDoc(referenceCollection, saveDocument);
          fileStorage.id = documentCollection.id;
          resolve(fileStorage);
        } catch (error) {
          console.log(error);
          await deleteObject(storageReference);
          reject(error);
        }
      });
    })
  }

  /**
   * delete a file from Firestore
   * 
   * @param {string} storageId - firebase document id
   * @returns {Promise<void>}
   */
  async delete(storageId) {
    if (storageId) {
      const storageResponse = await this.findFileStorageById(storageId);
      await deleteDoc(doc(firestore, environment.COLLECTION.FILE_STORAGES, storageResponse.id));
      const storageReference = ref(storage, storageResponse.filePath);
      return await deleteObject(storageReference);
    } else {
      return null;
    }
  }
}