import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, serverTimestamp, updateDoc } from "firebase/firestore";
import { firestore } from "../configs/firebase";
import { environment } from "configs/environment";
import { Question } from "models/Question";

export class QuestionRepository {
  /**
   * Retrieves all questions from the Firestore collection.
   * @returns {Promise<Array<Question>>} A Promise that resolves to an array of User objects.
   */
  async findAllQuestions() {
    const response = await getDocs(query(collection(firestore, environment.COLLECTION.QUESTIONS), orderBy("createdDate", "desc")))
    const parseResponse = response.docs.map(document => new Question({ id: document.id, ...document.data() }));
    console.log("findAllQuestions", parseResponse);
    return parseResponse;
  }

  /**
   * Retrieve a question from the Firestore collection.
   * @param {string} questionId - document id from firebase
   * @returns {Promise<Question>} A Promise that resolves to an array of User objects.
   */
  async findQuestionById(questionId) {
    const document = await getDoc(doc(firestore, environment.COLLECTION.QUESTIONS, questionId));
    if (document.exists()) {
      const parseQuestion = new Question({ id: document.id, ...document.data() });
      console.log("findQuestionById", parseQuestion);
      return parseQuestion;
    }
    return null;
  }

  /**
   * Saves a question to the Firestore collection.
   * If the question object has an ID, the corresponding document will be updated with the new data.
   * Otherwise, a new document will be created.
   * @param {Question} question The question object to save.
   * @returns {Promise<Question>} A Promise that resolves to the updated or newly created User object.
   */
  async save(question) {
    const referenceCollection = collection(firestore, environment.COLLECTION.QUESTIONS);
    const updateQuestion = { ...question };
    if (updateQuestion.id) {
      const accountId = updateQuestion.id;
      delete updateQuestion.id;
      updateQuestion.updatedDate = serverTimestamp();
      //check this logic
      updateQuestion.answers = updateQuestion.answers?.map(answer => ({...answer}));
      const documentReference = doc(firestore, environment.COLLECTION.QUESTIONS, accountId)
      await updateDoc(documentReference, updateQuestion)
      return new Question({ id: accountId, ...updateQuestion, updatedDate: new Date() });
    } else {
      delete updateQuestion.id;
      updateQuestion.updatedDate = serverTimestamp();
      updateQuestion.createdDate = serverTimestamp();
      updateQuestion.answers = updateQuestion.answers?.map(answer => ({...answer}));
      const response = await addDoc(referenceCollection, updateQuestion)
      return new Question({ ...question, id: response.id, createdDate: new Date() })
    }
  }

  /**
   * delete the question from firestore collection and all files in the storage
   * 
   * @param {string} questionId 
   * @returns {Promise<void>}
   */
  async delete(questionId) {
    const referenceCollection = doc(firestore, environment.COLLECTION.QUESTIONS, questionId);
    const querySnapshot = await getDoc(referenceCollection);
    if (querySnapshot.exists()) {
      return await deleteDoc(referenceCollection);
    }
    return null;
  }
}