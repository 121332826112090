
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import FormField from "components/layouts/ecommerce/products/new-product/components/FormField";
import { handleFormData, handleSelectFormData } from "services/HandleService";

export function ProductInfo(props) {
  return (
    <SoftBox>
      <SoftTypography variant="h5">Product Info</SoftTypography>
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField type="text" label="title" name="title" value={props.formData.title || ""} placeholder="eg. Oreo Overloaded" onChange={(event) => handleFormData(event, props.setFormData)} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={3}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                  Status
                </SoftTypography>
              </SoftBox>
              <SoftSelect defaultValue={props.formData.active != void 0 ? COURSE_STATUS[props.formData.active ? 0 : 1] : COURSE_STATUS[1]} options={COURSE_STATUS} onChange={(obj) => handleSelectFormData("active", obj.value, props.setFormData)} />
            </SoftBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <SoftBox mb={3}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                  Classroom
                </SoftTypography>
              </SoftBox>
              <SoftSelect defaultValue={props.formData.classroom != void 0 ? COURSE_YES_NO_SELECT[props.formData.classroom ? 0 : 1] : COURSE_YES_NO_SELECT[1]} options={COURSE_YES_NO_SELECT} onChange={(obj) => handleSelectFormData("classroom", obj.value, props.setFormData)} />
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField disabled={!props.formData.classroom} type="text" label="Classroom Title" name="classroomTitle" value={props.formData.classroomTitle || ""} placeholder="Enter classroom title" onChange={(event) => handleFormData(event, props.setFormData)} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField disabled={!props.formData.classroom} type="text" label="Schedule Link" name="scheduleLink" value={props.formData.scheduleLink || ""} placeholder="https://..." onChange={(event) => handleFormData(event, props.setFormData)} />
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">Short Description</SoftTypography>
              <SoftTypography variant="caption" fontWeight="regular" color="text"></SoftTypography>
            </SoftBox>
            <FormField type="text" name="shortDescription" placeholder="Enter with your short description for this course..." value={props.formData.shortDescription || ""} onChange={(event) => handleFormData(event, props.setFormData)} />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

ProductInfo.propTypes = {
  formData: PropTypes.any.isRequired,
  setFormData: PropTypes.func.isRequired
};

export const COURSE_YES_NO_SELECT = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const COURSE_STATUS = [
  { value: true, label: "Active" },
  { value: false, label: "Disabled" },
];
