export const FIELDS_NUMBER = ["price"];

/**
 * this function handle with normal inputs
 * use the property name="title" as example to define the property that will be 
 * 
 * @param {Event} event the click event
 * @param {Function} setState a react state
 */
export function handleFormData(event, setState) {
  if (event.target) {
    setState(previous => ({
      ...previous,
      [event.target.name]: FIELDS_NUMBER.includes(event.target.name) ? Number(event.target.value) : event.target.value
    }));
  }
}

/**
 * this function handle with inputs selects
 * 
 * @param {string} property - property key of the object that will be change
 * @param {any} value - value of the object tha will be add to the property
 * @param {Function} setState a react state
 */
export function handleSelectFormData(property, value, setState) {
  const event = {
    target: {
      name: property,
      value: value
    }
  }
  handleFormData(event, setState);
}

export function handleIsDateWithin7Days(date) {
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);
  const targetDate = new Date(date);
  return targetDate > sevenDaysAgo && targetDate <= today;
}
