import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import Footer from "components/examples/Footer";
import DataTable from "components/examples/Tables/DataTable";
import { useContext, useEffect, useState } from "react";
import { Context } from "contexts/contextState";
import { MainHeader } from "views/components/MainHeader";
import { QuestionActionCell } from "./components/QuestionActionCell";

export function QuestionsView(props) {
  /****************** hooks ********************/
  const { context } = useContext(Context);
  const [firstPageLoader, setFirstPageLoader] = useState(true);
  const [questionTable, setQuestionTable] = useState(QUESTION_TABLE);

  /****************** functions ********************/

  async function handlePageLoader() {
    const questionResponse = await context.questionRepository.findAllQuestions();

    setQuestionTable(previous => ({
      ...previous,
      rows: questionResponse.map(question => ({
        ...question,
        question: question.title,
        answer: context.formatterService.formatStringShortText(question.answers.find(answer => answer.isCorrectAnswer)?.title || "", 50),
        action: <QuestionActionCell question={question} />,
      }))
    }));
  }

  /****************** useEffects ********************/

  useEffect(() => {
    // it will just execute once
    if (firstPageLoader) {
      setFirstPageLoader(false);
      // codding ...
      handlePageLoader();
    }
  }, []);

  return (
    <DashboardLayout>
      <MainHeader />
      <SoftBox my={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                All Multiple Choice
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Comprehensive catalog of available questions block
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction="row">
              <Link to="/admin/questions/create">
                <SoftButton variant="gradient" color="info" size="small">
                  + new question block
                </SoftButton>
              </Link>
            </Stack>
          </SoftBox>
          <DataTable
            canSearch
            table={questionTable}
            entriesPerPage={{
              defaultValue: 25,
              entries: [10, 25, 50, 100],
            }}
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

const QUESTION_TABLE = {
  columns: [
    { Header: "question", accessor: "question", width: "50%" },
    { Header: "answer", accessor: "answer", width: "40%" },
    { Header: "action", accessor: "action" },
  ],
  rows: [],
};