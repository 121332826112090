import validator from "validator";

export class ValidatorService {
  isEmail(email) {
    return validator.isEmail(email);
  }

  isMobilePhone(phone) {
    // Define the validator options for US and Canada phone numbers
    const options = {
      // strictMode: true,
      ignore: "+()- ",
      // pattern: '^\\d{10}$'
    };
    return validator.isMobilePhone(phone, ['en-US', 'en-CA'], options);
  }
  
  isStrongPassword(password) {
    // Define the validator options for strong password
    const options = {
      minLength: 6,
      minLowercase: 0,
      minUppercase: 0,
      minNumbers: 0,
      minSymbols: 0,
      returnScore: false,
      pointsPerUnique: 1,
      pointsPerRepeat: 0.5,
      pointsForContainingLower: 10,
      pointsForContainingUpper: 10,
      pointsForContainingNumber: 10,
      pointsForContainingSymbol: 10,
    };
    return validator.isStrongPassword(password, options);
  }
}
