import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useState } from "react";
import { DeleteQuestionModal } from "./DeleteQuestionModal";

export function QuestionActionCell(props) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <SoftBox display="flex" alignItems="center">
      <DeleteQuestionModal question={props.question} open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} />
      <SoftBox mx={2} display="flex" alignItems="center">
        <SoftTypography component={Link} to={"/admin/questions/all/edit/" + props.question.id} variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Edit Question" placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
      <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} onClick={() => setOpenDeleteModal(true)}>
        <Tooltip title="Delete Question" placement="left">
          <Icon>delete</Icon>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
}

QuestionActionCell.propTypes = {
  question: PropTypes.any,
};