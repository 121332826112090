import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";

export function AnswerActionCell(props) {
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftBox mx={2}>
        <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} onClick={() => props.onDelete(props.answer)}>
          <Tooltip title="Remove Answer" placement="left">
            <Icon>delete</Icon>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

AnswerActionCell.propTypes = {
  answer: PropTypes.any,
  onDelete: PropTypes.func
};