import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";
import SoftBox from "components/SoftBox";
import SoftDropzoneRoot from "components/SoftDropzone/SoftDropzoneRoot";
import { environment } from "configs/environment";

export function SoftDropzone({ file, options }) {
  const dropzoneRef = useRef();

  useEffect(() => {
    Dropzone.autoDiscover = false;

    function createDropzone() {
      const dropzone = new Dropzone(dropzoneRef.current, { ...options });
      if (!!file) {
        dropzone.addFile(file);
      }
      return dropzone;
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0) Dropzone.instances.forEach((dz) => dz.destroy());
    }
    createDropzone();
    return () => removeDropzone();
  }, []);

  return (
    <SoftDropzoneRoot
      component="form"
      action={environment.API.HOST + "/health"}
      ref={dropzoneRef}
      className="form-control dropzone"
    >
      <SoftBox className="fallback">
        <SoftBox component="input" name="file" type="file" multiple />
      </SoftBox>
    </SoftDropzoneRoot>
  );
}

SoftDropzone.propTypes = {
  file: PropTypes.any,
  options: PropTypes.objectOf(PropTypes.any).isRequired,
};
