/**
=========================================================
* Hydra Security Admin - v4.0.0
=========================================================

* Product Page: https://codejays.com//product/soft-ui-dashboard-pro-react
* Copyright 2022 Code Jays (https://codejays.com/)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  The rgba() function helps you to create a rgba color code, it uses the hexToRgb() function
  to convert the hex code into rgb for using it inside the rgba color format.
 */

// Hydra Security Admin helper functions
import hexToRgb from "assets/theme/functions/hexToRgb";

function rgba(color, opacity) {
  return `rgba(${hexToRgb(color)}, ${opacity})`;
}

export default rgba;
