import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import { useContext } from "react";
import { Context } from "contexts/contextState";
import { handleIsDateWithin7Days } from "services/HandleService";

export function CourseProductCell(props) {
  const { context } = useContext(Context);

  return (
    <SoftBox display="flex" alignItems="center" pr={2}>
      <SoftBox mr={2}>
        <SoftAvatar src={props.image} alt={props.name} variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {props.name}
        </SoftTypography>
        <SoftTypography variant="button" fontWeight="medium" color="secondary">
          <SoftTypography component="span" variant="button" color="success">
            {handleIsDateWithin7Days(props.createdDate) ? "New" : ""}
          </SoftTypography>{" "}
          {context.formatterService.formatStringShortText(context.formatterService.removeAllHtml(props.description))}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

// Typechecking props for the ProductCell
CourseProductCell.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  createdDate: PropTypes.object.isRequired
};
