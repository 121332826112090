import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { navbar, navbarContainer, navbarRow, navbarIconButton } from "components/examples/Navbars/DashboardNavbar/styles";
import { useSoftUIController, setTransparentNavbar } from "components/layouts/context";
import { Context } from "contexts/contextState";
import { Breadcrumbs } from "./Breadcrumbs";

export function MainHeader({ absolute, light, isMini }) {
  /****************** hooks ********************/
  const { context } = useContext(Context);
  const navigate = useNavigate();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { transparentNavbar, fixedNavbar } = controller;
  const route = useLocation().pathname.split("/").slice(1);


  async function handleLogoOut() {
    await context.authenticationService.signOut();
    navigate("/authentication/sign-in");
  }

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);


  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </SoftBox>
        <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
          <SoftBox color={light ? "white" : "inherit"}>
            <IconButton sx={navbarIconButton} size="small" onClick={handleLogoOut}>
              <Icon sx={({ palette: { dark, white } }) => ({ color: light ? white.main : dark.main, })}>
                logout
              </Icon>
              <SoftTypography
                variant="button"
                fontWeight="medium"
                color={light ? "white" : "dark"}
              >
                Login Out
              </SoftTypography>
            </IconButton>
          </SoftBox>
        </SoftBox>
      </Toolbar>
    </AppBar>
  );
}

MainHeader.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

MainHeader.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};
