import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export function PaymentActionCell(props) {
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftBox mx={2} display="flex" alignItems="center">
        <SoftTypography component={Link} to={"/admin/customers/all/edit/" + props.customer.id} variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="View Customer" placement="top">
            <Icon>visibility</Icon>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

PaymentActionCell.propTypes = {
  customer: PropTypes.any.isRequired,
};