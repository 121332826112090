import { Answer } from "./Answer";

export class Question {
  constructor(entity) {
    this.id = entity?.id || null;
    this.title = entity?.title || "";
    this.answers = entity?.answers?.map(answer => new Answer(answer)) || [];
    this.createdDate = entity?.createdDate instanceof Date ? entity?.createdDate : entity?.createdDate?.toDate() || null;
    this.updatedDate = entity?.updatedDate instanceof Date ? entity?.updatedDate : entity?.updatedDate?.toDate() || null;
  }
}
