import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import FormField from "components/layouts/applications/wizard/components/FormField";
import { Context } from "contexts/contextState";
import { Course } from "models/Course";
import { LESSON_CONTENT_ENUM, LessonContent } from "models/Lesson";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { errorAlert, successAlert } from "views/components/Alerts";
import { handleFormData, handleSelectFormData } from "services/HandleService";
import { LESSON_CONTENT_SELECT } from "../LessonView";
import SoftEditor from "components/SoftEditor";

export function EditLessonContentModal(props) {
  const { context } = useContext(Context);
  const [lessonContentForm, setLessonContentForm] = useState(new LessonContent(props.lessonContent));
  const [processing, setProcessing] = useState(false);

  async function handleSaveLessonContent() {
    setProcessing(true);
    try {
      const course = new Course(props.course);
      lessonContentForm.updatedDate = new Date();
      const lesson = course.lessons.find(lesson => lesson.id == props.lesson.id);
      const lessonContentIndex = lesson.lessonContents.findIndex(lessonContent => lessonContent.id == props.lessonContent.id);
      lesson.lessonContents[lessonContentIndex] = lessonContentForm;
      await context.courseRepository.save(course);
      successAlert("Success!", "The edits made to your lesson content have been successfully updated in the database.");
      props.onClose();
    } catch (error) {
      console.log(error);
      errorAlert("Error!", "Failed to update a lesson content in our database. Please check your connection and try again. If the issue persists, please contact our support team for assistance.");
    } finally {
      setProcessing(false);
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} sx={{ overflow: "visible" }} fullWidth>
      <SoftBox mx="16px" mt="16px" >
        <SoftTypography variant="h5">Lesson Content Information</SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          Edit all information about: {props.lessonContent.title}
        </SoftTypography>
      </SoftBox>
      <DialogContent sx={{ overflow: "visible" }}>
        <SoftBox display="flex" flex="1" flexDirection="column" mt={1} minWidth="480px">
          <FormField type="text" label="title" name="title" value={lessonContentForm.title || ""} placeholder="eg. Oreo Overloaded" onChange={(event) => handleFormData(event, setLessonContentForm)} />
          <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
            <SoftBox my={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Lesson Type
              </SoftTypography>
            </SoftBox>
            <SoftSelect
              placeholder="Select Type"
              value={LESSON_CONTENT_SELECT.find(select => select.value == lessonContentForm.type) || ""}
              options={LESSON_CONTENT_SELECT}
              onChange={(obj) => handleSelectFormData("type", obj.value, setLessonContentForm)}
            />
          </SoftBox>
          {/* text tab */}
          <Box display={lessonContentForm.type == LESSON_CONTENT_ENUM.TEXT ? "block" : "none"} mt="20px">
            <SoftEditor defaultValue={lessonContentForm.text} onChange={(value) => handleSelectFormData("text", value, setLessonContentForm)} />
          </Box>
          {/* select file */}
          <SoftBox display={lessonContentForm.type == LESSON_CONTENT_ENUM.ARCHIVE ? "flex" : "none"} flex="1" flexDirection="column" justifyContent="flex-end" height="100%">
            <SoftBox my={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Select Archive
              </SoftTypography>
            </SoftBox>
            <SoftSelect
              placeholder="Select Archive"
              value={props.archivesSelector.find(select => select.value == lessonContentForm.archiveId)}
              options={props.archivesSelector}
              onChange={(obj) => handleSelectFormData("archiveId", obj.value, setLessonContentForm)}
            />
          </SoftBox>
          {/* video tab */}
          <Box display={lessonContentForm.type == LESSON_CONTENT_ENUM.VIDEO ? "block" : "none"}>
            <FormField label="Youtube Link" placeholder="https://..."
              value={lessonContentForm.videoLink || ""} name="videoLink" onChange={(event) => handleFormData(event, setLessonContentForm)}
            />
          </Box>
          {/* question tab */}
          <SoftBox display={lessonContentForm.type == LESSON_CONTENT_ENUM.QUESTION ? "flex" : "none"} flexDirection="column" justifyContent="flex-end" height="100%">
            <SoftBox my={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Multiple Choice Select
              </SoftTypography>
            </SoftBox>
            <SoftSelect
              placeholder="Select Multiple Choice"
              value={props.questionsSelect.find(select => select.value == lessonContentForm.questionId) || ""}
              options={props.questionsSelect}
              onChange={(obj) => handleSelectFormData("questionId", obj.value, setLessonContentForm)}
            />
          </SoftBox>
        </SoftBox>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flex="1" mt="20px">
          <SoftButton variant="text" color="info" onClick={props.onClose} disabled={processing}>Cancel</SoftButton>
          <SoftButton variant="text" color="info"
            onClick={handleSaveLessonContent}
            disabled={!(context.permission.root || context.permission.course.update)}
          >
            Confirm
          </SoftButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

EditLessonContentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  course: PropTypes.any,
  lesson: PropTypes.any,
  lessonContent: PropTypes.any,
  questionsSelect: PropTypes.any,
  archivesSelector: PropTypes.any,
}