import { Box, Card, Step, StepLabel, Stepper } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "components/examples/Footer";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import { useContext, useEffect, useState } from "react";
import { ProductInfo } from "./fragments/ProductInfo";
import { MediaFile } from "./fragments/MediaFile";
import SoftButton from "components/SoftButton";
import { PricingInfo } from "./fragments/PricingInfo";
import { Context } from "contexts/contextState";
import { Course } from "models/Course";
import { doc } from "firebase/firestore";
import { firestore } from "services/firebase/configs/firebase";
import { environment } from "configs/environment";
import { MainHeader } from "views/components/MainHeader";
import { errorAlert } from "views/components/Alerts";
import { successAlert } from "views/components/Alerts";
import { useNavigate } from "react-router-dom";
import { ProgressBarModal } from "views/components/ProgressBarModal";
import { STORAGE_TYPE } from "models/FileStorage";

// use this component to create new pages view
export function CreateCourseView(props) {
  /****************** hooks ********************/
  const navigate = useNavigate();
  const { context } = useContext(Context);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [processing, setProcessing] = useState(false);
  const [percent, setPercent] = useState(0);

  /****************** functions ********************/

  async function handleCreateCourseSubmit() {
    setProcessing(true);
    try {
      const course = new Course(formData);
      if (formData.thumbnail) {
        const responseThumbnailStorage = await context.fileStorageRepository.uploadFile(formData.thumbnail, STORAGE_TYPE.IMAGE, (processPercent) => {
          setPercent(processPercent);
        });
        if (responseThumbnailStorage) {
          course.thumbnailReference = doc(firestore, environment.COLLECTION.FILE_STORAGES, responseThumbnailStorage.id);
        }
      }
      const responseCourse = await context.courseRepository.save(course);
      console.log("handleCreateCourseSubmit", responseCourse);
      successAlert("Success!", "The new course has been successfully registered.").then(() => {
        navigate("/admin/courses/all/edit/" + responseCourse.id);
      });
    } catch (error) {
      console.log("handleCreateCourseSubmit", error);
      errorAlert("Error!", "Failed to register a new course in our database. Please check your connection and try again. If the issue persists, please contact our support team for assistance.");
    } finally {
      setProcessing(false);
    }
  }

  /****************** useEffects ********************/

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <DashboardLayout>
      <ProgressBarModal open={processing} percent={percent} />
      <MainHeader />
      <SoftBox my={3}>
        <Card style={{ overflow: "visible" }}>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Create New Course
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Please complete all the fields below to create a new course.
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <Box p={3}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {STEPS.map((label) => (<Step key={label}><StepLabel>{label}</StepLabel></Step>))}
            </Stepper>
            {activeStep == 0 && (<ProductInfo formData={formData} setFormData={setFormData} />)}
            {activeStep == 1 && (<MediaFile formData={formData} setFormData={setFormData} />)}
            {activeStep == 2 && (<PricingInfo formData={formData} setFormData={setFormData} />)}
            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
              {activeStep == 0 ? (<SoftBox />) : (
                <SoftButton variant="gradient" color="secondary" onClick={() => setActiveStep(activeStep - 1)}> BACK </SoftButton>
              )}
              <SoftButton disabled={processing} variant="gradient" color="dark" onClick={!(STEPS.length - 1 == activeStep) ? () => setActiveStep(activeStep + 1) : handleCreateCourseSubmit} >
                {(STEPS.length - 1 == activeStep) ? "SEND" : "NEXT"}
              </SoftButton>
            </SoftBox>
          </Box>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  )
}

const STEPS = ["1. Product Info", "2. Media", "3. Pricing"];