/* eslint-disable react/prop-types */
import IdCell from "components/layouts/ecommerce/orders/order-list/components/IdCell";
import DefaultCell from "components/layouts/ecommerce/orders/order-list/components/DefaultCell";
import StatusCell from "components/layouts/ecommerce/orders/order-list/components/StatusCell";
import CustomerCell from "components/layouts/ecommerce/orders/order-list/components/CustomerCell";

// Images
import { Link } from "react-router-dom";

function handlePaymentStatus(value) {
  if (["completed", "new"].includes(value)) {
    return <StatusCell icon="done" color="success" status={value == "new" ? "Registered (Paid)" : "Completed"} />;
  } else if (value === "unPaid") {
    return <StatusCell icon="attach_money" color="dark" status="Registered (Unpaid)" />;
  } else {
    return <StatusCell icon="close" color="error" status={value} />;
  }
}

export const PAYMENT_LEGACY_TABLE = {
  columns: [
    { Header: "Stripe payment id", accessor: "id", Cell: ({ value }) => <DefaultCell value={value} /> },
    { Header: "customer", accessor: "customer", Cell: ({ value: [customer, data] }) => <Link to={"/admin/customers/all/edit/" + customer?.id}><CustomerCell image={data.image} color={data.color || "dark"} name={(customer?.firstName || "") + " " + (customer?.lastName || "")} /></Link>},
    { Header: "product", accessor: "product", Cell: ({ value }) => <DefaultCell value={typeof value === "string" ? value : value.name} /> },
    { Header: "status", accessor: "status", Cell: ({ value }) => handlePaymentStatus(value) },
    { Header: "revenue", accessor: "revenue", Cell: ({ value }) => <DefaultCell value={value} /> },
    { Header: "date", accessor: "date", Cell: ({ value }) => <DefaultCell value={value} /> },
  ],

  rows: [],
};
