import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { VideoPlayer } from "views/components/VideoPlayer";

export function VideoPreviewModal(props) {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <SoftBox mx="16px" mt="16px">
        <SoftTypography variant="h5">Video Lesson: {props.lessonContent.title}</SoftTypography>
      </SoftBox>
      <DialogContent>
        <SoftBox display="flex" flex="1" flexDirection="column" mt={1}>
          <VideoPlayer videoUrl={props.lessonContent.videoLink} />
        </SoftBox>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flex="1">
          <SoftButton variant="text" color="info" onClick={props.onClose}>Close</SoftButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

VideoPreviewModal.propTypes = {
  lessonContent: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}