import { useContext, useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";
import SoftSelect from "components/SoftSelect";
import FormField from "components/layouts/ecommerce/products/edit-product/components/FormField";
import PropTypes from "prop-types";
import { COURSE_YES_NO_SELECT, COURSE_STATUS } from "views/courses/create/fragments/ProductInfo";
import { CURRENCY, PAYMENT_METHODS } from "views/courses/create/fragments/PricingInfo";
import SoftButton from "components/SoftButton";
import { Context } from "contexts/contextState";
import { errorAlert, successAlert } from "views/components/Alerts";
import { handleFormData, handleSelectFormData } from "services/HandleService";
import { STORAGE_TYPE } from "models/FileStorage";
import { Box } from "@mui/material";
import { DocumentModal } from "../components/DocumentModal";

export function EditProductInfo(props) {
  const { context } = useContext(Context);
  const [consentDocuments, setConsentDocuments] = useState([]);
  const [documentUploadModal, setDocumentUploadModal] = useState(false);

  async function updateProductInfos() {
    try {
      const response = await context.courseRepository.save({ ...props.course });
      console.log("updateProductInfos", response);
      successAlert("Success!", "The edits made to your course have been successfully updated in the database.");
    } catch (error) {
      console.log("updateProductInfos", error);
      errorAlert("Error!", "An error occurred while updating the database with the edits made to your course. Please try again later.");
    }
  }

  function handleLoadConsentDocuments() {
    context.fileStorageRepository.findAllFileByType(STORAGE_TYPE.DOCUMENT)
      .then(response => setConsentDocuments(response.map(storage => ({ label: storage.fileName, value: storage.id }))));
  }

  useEffect(() => {
    handleLoadConsentDocuments();
  }, []);

  useEffect(() => {
    console.log(consentDocuments);
  }, [consentDocuments]);

  return (
    <Card sx={{ overflow: "visible" }}>
      <DocumentModal open={documentUploadModal} onClose={() => {
        handleLoadConsentDocuments();
        setDocumentUploadModal(false);
      }} />
      <SoftBox p={3}>
        <SoftTypography variant="h5">Information</SoftTypography>
        <SoftBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField type="text" label="title" name="title" value={props.course.title} onChange={(event) => handleFormData(event, props.setCourse)} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SoftBox mb={3}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                    Status
                  </SoftTypography>
                </SoftBox>
                <SoftSelect value={props.course.active != void 0 ? COURSE_STATUS[props.course.active ? 0 : 1] : COURSE_STATUS[1]} options={COURSE_STATUS} onChange={(obj) => handleSelectFormData("active", obj.value, props.setCourse)} />
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <SoftBox mb={3}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                  Classroom
                </SoftTypography>
              </SoftBox>
              <SoftSelect value={COURSE_YES_NO_SELECT.find(classroom => classroom.value == props.course.classroom) || COURSE_YES_NO_SELECT[1]} options={COURSE_YES_NO_SELECT} onChange={(obj) => handleSelectFormData("classroom", obj.value, props.setCourse)} />
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormField disabled={!props.course.classroom} type="text" label="Classroom Title" name="classroomTitle" value={props.course.classroomTitle || ""} placeholder="Enter classroom title" onChange={(event) => handleFormData(event, props.setCourse)} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField disabled={!props.course.classroom} type="text" label="Schedule Link" name="scheduleLink" value={props.course.scheduleLink || ""} placeholder="https://..." onChange={(event) => handleFormData(event, props.setCourse)} />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField type="text" label="Short Description" name="shortDescription" value={props.course.shortDescription || ""} placeholder="Enter with your short description..." onChange={(event) => handleFormData(event, props.setCourse)} />
          </Grid>
        </Grid>
        <SoftBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Description&nbsp;&nbsp;
                  <SoftTypography variant="caption" fontWeight="regular" color="text">
                    (optional)
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
              {/* there is a typing bug on this component so it was implemented like that */}
              {props.course.description != undefined && (
                <SoftEditor
                  defaultValue={props.course.description}
                  onChange={(value) => handleSelectFormData("description", value, props.setCourse)}
                />
              )}
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mt={3}>
          <SoftTypography variant="h5">Consent and Release Form</SoftTypography>
          <SoftBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <SoftBox mb={3}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                      Active
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect value={COURSE_YES_NO_SELECT.find(classroom => classroom.value == props.course.consentReleaseForm) || COURSE_YES_NO_SELECT[1]} options={COURSE_YES_NO_SELECT} onChange={(obj) => handleSelectFormData("consentReleaseForm", obj.value, props.setCourse)} />
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormField disabled={!props.course.consentReleaseForm} type="text" label="Consent Title" name="consentReleaseFormDescription" value={props.course.consentReleaseFormDescription || ""} placeholder="Enter classroom title" onChange={(event) => handleFormData(event, props.setCourse)} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box display="flex" justifyContent="center" alignItems="end" mt="20px">
                  <SoftButton variant="outlined" color="info" size="small"
                    onClick={() => setDocumentUploadModal(true)}
                    disabled={!(context.permission.root || context.permission.course.update) || !props.course.consentReleaseForm}
                  >
                    Upload Document
                  </SoftButton>
                </Box>
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox display={props.course.consentReleaseForm ? "flex" : "none"}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Consent Document
                  </SoftTypography>
                </SoftBox>
                <SoftSelect value={consentDocuments.find(consent => consent.value == props.course.consentReleaseDocumentId)} options={consentDocuments} size="large" onChange={(obj) => handleSelectFormData("consentReleaseDocumentId", obj.value, props.setCourse)} />
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
        <SoftBox mt={3}>
          <SoftTypography variant="h5">Pricing</SoftTypography>
          <SoftBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField type="number" label="price" name="price" value={props.course?.price || ""} placeholder="99.00" onChange={(event) => handleFormData(event, props.setCourse)} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize" >
                    Currency
                  </SoftTypography>
                </SoftBox>
                <SoftSelect value={props.course?.currency || ""} options={CURRENCY} onChange={(obj) => handleSelectFormData("currency", obj, props.setCourse)} />
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Payment methods
                  </SoftTypography>
                </SoftBox>
                <SoftSelect value={props.course.paymentMethods || []} options={PAYMENT_METHODS} size="large" isMulti onChange={(obj) => handleSelectFormData("paymentMethods", obj, props.setCourse)} />
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
        <SoftBox mt={5} display="flex" justifyContent="end">
          <SoftButton variant="gradient" color="info" size="small"
            onClick={updateProductInfos}
            disabled={!(context.permission.root || context.permission.course.update)}
          >
            Save
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

EditProductInfo.propTypes = {
  course: PropTypes.object.isRequired,
  setCourse: PropTypes.func.isRequired
}
