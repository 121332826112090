import YoutubePlayer from "react-player";
import VimeoPlayer from "react-player/vimeo";
import PropTypes from "prop-types";

export function VideoPlayer(props) {
  return (props.videoUrl?.includes("vimeo") ? <VimeoPlayer url={props.videoUrl} width="540px" controls /> : <YoutubePlayer url={props.videoUrl} width="540px" />);
}

VideoPlayer.propTypes = {
  videoUrl: PropTypes.string 
}