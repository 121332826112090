import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { Context } from "contexts/contextState";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { successAlert } from "views/components/Alerts";
import { ProgressBarModal } from "views/components/ProgressBarModal";
import { SoftDropzone } from "views/components/SoftDropzone";
import { handleSelectFormData } from "services/HandleService";
import { STORAGE_TYPE } from "models/FileStorage";
import { CERTIFICATION_TYPE, Certification, User } from "models/User";

export function CertificationModal(props) {
  const { context } = useContext(Context);
  const [processing, setProcessing] = useState(false);
  const [percent, setPercent] = useState(0);
  const [certificationFile, setCertificationFile] = useState({});

  async function handleCertificationUpdate() {
    setProcessing(true);
    try {
      if (certificationFile.file) {
        const customer = new User(props.customer);
        const certificationStorageResponse = await context.fileStorageRepository.uploadFile(certificationFile.file, STORAGE_TYPE.CERTIFICATION, (progressPercent) => {
          setPercent(progressPercent);
        });

        // delete old certification for the same course
        const lastCourseCertification = customer.certifications.find(certification => certification.courseId == props.course.id);
        if (lastCourseCertification) {
          context.fileStorageRepository.delete(lastCourseCertification.documentId);
          customer.certifications = customer.certifications.filter(cert => cert.id != lastCourseCertification.id);
        }

        const certification = new Certification();
        certification.courseId = props.course.id;
        certification.type = CERTIFICATION_TYPE.MANUAL;
        certification.documentId = certificationStorageResponse.id;
        customer.certifications.push(certification);

        const courseResponse = await context.userRepository.saveUser(customer);
        console.log("handleCertificationUpdate", courseResponse);
        successAlert("Success!", "The certification has been successfully updated.").then(() => {
          window.location.reload();
        });
        setCertificationFile({});
        props.onClose();
      }
    } catch (error) {
      console.log("handleCertificationUpdate", error);
    } finally {
      setProcessing(false);
    }
  }

  useEffect(() => {
    console.log(certificationFile);
  }, [certificationFile]);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <ProgressBarModal open={processing} percent={percent} onClose={props.onClose} />
      <SoftBox mx="16px" mt="16px">
        <SoftTypography variant="h5">Certification</SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          If you confirm the update, the certification for complete this course will be set.
        </SoftTypography>
      </SoftBox>
      <DialogContent>
        <SoftBox display="flex" flex="1" flexDirection="column" mt={1} minWidth="480px">
          <SoftBox mt={1}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                Select Certification
              </SoftTypography>
            </SoftBox>
            <SoftDropzone file={certificationFile.file} options={{
              addRemoveLinks: true,
              maxFiles: 1, // Allow only one file
              acceptedFiles: "application/pdf", // Accept only pdf files
              dictDefaultMessage: "Drop a pdf file here or click to upload",
              init: function () {
                this.on("addedfile", (file) => {
                  if (this.files.length > 1) {
                    this.removeFile(this.files[0]);
                  }
                  handleSelectFormData("file", file, setCertificationFile);
                });
                this.on("removedfile", (file) => {
                  handleSelectFormData("file", null, setCertificationFile);
                });
              },
            }} />
          </SoftBox>
        </SoftBox>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flex="1">
          <SoftButton variant="text" color="info" onClick={props.onClose} disabled={processing}>Cancel</SoftButton>
          <SoftButton variant="text" color="info"
            onClick={handleCertificationUpdate}
            disabled={!(context.permission.root || context.permission.customer.update)}
          >
            Confirm
          </SoftButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

CertificationModal.propTypes = {
  customer: PropTypes.object,
  course: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func
}