import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import { Context } from "contexts/contextState";
import { User } from "models/User";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { handleSelectFormData } from "services/HandleService";
import { errorAlert, successAlert } from "views/components/Alerts";

export function AddCustomerCourseModal(props) {
  const { context } = useContext(Context);
  const [courses, setCourses] = useState({});
  const [formData, setFormData] = useState([]);
  const [processing, setProcessing] = useState(false);

  async function handleAddCustomerCourse() {
    setProcessing(true);
    try {
      const customer = new User(props.customer);
      customer.purchases = [...new Set([...customer.purchases]), ...formData.courses.map(course => course.value)]
      await context.userRepository.saveUser(customer);
      successAlert("Success!", "The course has been added.").then(() => {
        window.location.reload();
      });
      props.onClose();
    } catch (error) {
      console.log("handleDeleteCustomer", error);
      errorAlert("Error!", "Failed to add a customer course in our database. Please check your connection and try again. If the issue persists, please contact our support team for assistance.");
    } finally {
      setProcessing(false);
    }
  }

  useEffect(() => {
    context.courseRepository.findAllCourse().then(response => {
      setCourses(response.map(course => ({ label: course.title, value: course.id })));
    })
  }, [])

  return (
    <Dialog open={props.open} onClose={props.onClose} sx={{ overflow: "visible" }}>
      <DialogTitle>
        Are you certain you want to add a course for this customer?
      </DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        <SoftBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Payment methods
                </SoftTypography>
              </SoftBox>
              <SoftSelect defaultValue={[]} options={courses} size="large" isMulti onChange={(obj) => handleSelectFormData("courses", obj, setFormData)} />
            </Grid>
          </Grid>
        </SoftBox>
        <br />
        <SoftTypography variant="body2">
          Click the <b>Add</b> button to confirm or <b>Cancel</b> to go back.
        </SoftTypography>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flex="1">
          <SoftButton variant="text" color="error"
            onClick={props.onClose}
            disabled={!(context.permission.root || context.permission.customer.delete)}
          >
            CANCEL
          </SoftButton>
          <SoftButton variant="text" color="info" onClick={handleAddCustomerCourse} disabled={processing}>ADD</SoftButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

AddCustomerCourseModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
};