import { Card, Stack, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "components/examples/Footer";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import { useContext, useEffect, useState } from "react"
import { MainHeader } from "../../components/MainHeader";
import { Context } from "contexts/contextState";
import { User } from "models/User";
import { CUSTOMER_PAYMENT_TABLE } from "views/constants/tables";
import DataTable from "components/examples/Tables/DataTable";
import SoftBadge from "components/SoftBadge";
import { PaymentActionCell } from "./components/table/PaymentActionCell";
import { Course } from "models/Course";
import { PAYMENT_METHODS } from "views/courses/create/fragments/PricingInfo";

// use this component to create new pages view
export function PaymentsView(props) {
  /****************** hooks ********************/
  const { context } = useContext(Context);

  // states
  const [paymentTable, setPaymentTable] = useState(CUSTOMER_PAYMENT_TABLE);

  /****************** functions ********************/

  async function handlePageLoader() {
    const [paymentDatabase, userDatabase, courseDatabase, imagesResponse] = await Promise.all([
      context.paymentRepository.findAllPayment(),
      context.userRepository.findAllUser(),
      context.courseRepository.findAllCourse(),
      context.fileStorageRepository.findAllImages()
    ]);
    const userDatabaseParseMap = new Map(userDatabase.map(customer => [customer.id, customer]));
    const courseDatabaseParseMap = new Map(courseDatabase.map(course => [course.id, course]))

    setPaymentTable(previous => ({
      ...previous,
      rows: paymentDatabase.map(payment => {
        const course = new Course(courseDatabaseParseMap.get(payment.productId));
        const customer = new User(userDatabaseParseMap.get(payment.customerId));

        return ({
          ...payment,
          name: [
            (customer.firstName || "") + " " + (customer.lastName || ""),
            imagesResponse.find(image => image.id == customer.imageProfileReference?.id)?.fileUrl || "",
            customer.email,
            customer.createdDate
          ],
          shortDescription: course.shortDescription,
          product: [course.title, imagesResponse.find(image => image.id == course?.thumbnailReference?.id)?.fileUrl],
          status: customer.purchases?.find(purchaseId => course.id == purchaseId) ?
            <SoftBadge variant="contained" color="success" size="xs" badgeContent="Completed" container /> :
            customer.payInPersonPurchases?.find(paymentId => course.id == paymentId) ?
              <SoftBadge variant="contained" color="warning" size="xs" badgeContent="Pay In Person" container /> :
              <Tooltip title="A payment was made, but the course is not added for the customer."><SoftBadge variant="contained" color="error" size="xs" badgeContent="Error" container /></Tooltip>,
          paymentMethod: PAYMENT_METHODS.find(method => method.value == payment.paymentMethod?.value)?.label || "Not identified",
          action: <PaymentActionCell customer={customer} />
        })
      })
    })
    )
  }

  /****************** useEffects ********************/

  useEffect(() => {
    handlePageLoader();
  }, []);

  return (
    <DashboardLayout>
      <MainHeader />
      <SoftBox my={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Payments
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                List of payments made by customers.
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction="row">

            </Stack>
          </SoftBox>
          <DataTable
            canSearch
            table={paymentTable}
            entriesPerPage={{
              defaultValue: 25,
              entries: [10, 25, 50, 100],
            }}
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  )
}