import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Switch from "@mui/material/Switch";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import TableCell from "components/layouts/pages/account/settings/components/TableCell";
import SoftButton from "components/SoftButton";
import { useContext, useEffect, useState } from "react";
import { ACCOUNT_PERMISSION, User } from "models/User";
import { Context } from "contexts/contextState";
import { errorAlert, successAlert } from "views/components/Alerts";

export function EditCustomerPermission(props) {
  const { context } = useContext(Context);
  const [permission, setPermission] = useState(props.customer.permission || ACCOUNT_PERMISSION);

  async function handleUpdatePermission() {
    try {
      const customer = new User(props.customer);
      const customerResponse = await context.userRepository.saveUser(customer);
      console.log("handleUpdateInfos", customerResponse);
      successAlert("Success!", "The edits made to your customer have been successfully updated in the database.");
    } catch (error) {
      errorAlert("Error!", "An error occurred while updating the database with the edits made to your course. Please try again later.");
      console.log("handleUpdateInfos", error);
    }
  }

  useEffect(() => {
    console.log(permission);
    props.setCustomer(previous => ({ ...previous, permission: permission }));
  }, [permission]);

  return (
    <Card id="notifications">
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Permissions</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Please select the appropriate user permissions for this admin page.
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <SoftBox minWidth="auto">
          <Table sx={{ minWidth: "36rem" }}>
            <SoftBox component="thead">
              <TableRow>
                <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>Admin</TableCell>
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>Status</TableCell>
              </TableRow>
            </SoftBox>
            <TableBody>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography display="block" variant="button" fontWeight="regular">
                      Root
                    </SoftTypography>
                    <SoftTypography variant="caption" color="text" fontWeight="regular">
                      The ultimate authority with unrestricted control and access.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch disabled checked={permission.root} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography display="block" variant="button" fontWeight="regular">
                      Admin
                    </SoftTypography>
                    <SoftTypography variant="caption" color="text" fontWeight="regular">
                      Empowering administrators with comprehensive control and management capabilities.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch checked={permission.admin} onChange={event => setPermission(previous => ({ ...previous, admin: !permission.admin }))} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </SoftBox>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <SoftBox minWidth="auto">
          <Table sx={{ minWidth: "36rem" }}>
            <SoftBox component="thead">
              <TableRow>
                <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>Activity</TableCell>
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>View</TableCell>
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>Update</TableCell>
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>Delete</TableCell>
              </TableRow>
            </SoftBox>
            <TableBody>
              {/* customer */}
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography display="block" variant="button" fontWeight="regular">
                      Customers
                    </SoftTypography>
                    <SoftTypography variant="caption" color="text" fontWeight="regular">
                      Complete permissions for managing the customer section.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch checked={permission.customer?.view} onChange={() => setPermission(previous => ({
                    ...previous, customer: {
                      ...previous.customer,
                      view: !permission.customer?.view
                    }
                  }))} />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch checked={permission.customer?.update} onChange={() => setPermission(previous => ({
                    ...previous, customer: {
                      ...previous.customer,
                      update: !permission.customer?.update
                    }
                  }))} />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch checked={permission.customer?.delete} onChange={() => setPermission(previous => ({
                    ...previous, customer: {
                      ...previous.customer,
                      delete: !permission.customer?.delete
                    }
                  }))} />
                </TableCell>
              </TableRow>
              {/* course */}
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography display="block" variant="button" fontWeight="regular">
                      Courses
                    </SoftTypography>
                    <SoftTypography variant="caption" color="text" fontWeight="regular">
                      Complete permissions for managing the courses section.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch checked={permission.course?.view} onChange={() => setPermission(previous => ({
                    ...previous, course: {
                      ...previous.course,
                      view: !permission.course?.view
                    }
                  }))} />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch checked={permission.course?.update} onChange={() => setPermission(previous => ({
                    ...previous, course: {
                      ...previous.course,
                      update: !permission.course?.update
                    }
                  }))} />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch checked={permission.course?.delete} onChange={() => setPermission(previous => ({
                    ...previous, course: {
                      ...previous.course,
                      delete: !permission.course?.delete
                    }
                  }))} />
                </TableCell>
              </TableRow>
              {/* question */}
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography display="block" variant="button" fontWeight="regular">
                      Multiple Choice
                    </SoftTypography>
                    <SoftTypography variant="caption" color="text" fontWeight="regular">
                      Complete permissions for managing the multiple choice section.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch checked={permission.question?.view} onChange={() => setPermission(previous => ({
                    ...previous, question: {
                      ...previous.question,
                      view: !permission.question?.view
                    }
                  }))} />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch checked={permission.question?.update} onChange={() => setPermission(previous => ({
                    ...previous, question: {
                      ...previous.question,
                      update: !permission.question?.update
                    }
                  }))} />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch checked={permission.question?.delete} onChange={() => setPermission(previous => ({
                    ...previous, question: {
                      ...previous.question,
                      delete: !permission.question?.delete
                    }
                  }))} />
                </TableCell>
              </TableRow>
              {/* payment */}
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography display="block" variant="button" fontWeight="regular">
                      Payments
                    </SoftTypography>
                    <SoftTypography variant="caption" color="text" fontWeight="regular">
                      Complete permissions for managing the payments choice section.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch checked={permission.payment?.view} onChange={() => setPermission(previous => ({
                    ...previous, payment: {
                      ...previous.payment,
                      view: !permission.payment?.view
                    }
                  }))} />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch checked={permission.payment?.update} onChange={() => setPermission(previous => ({
                    ...previous, payment: {
                      ...previous.payment,
                      update: !permission.payment?.update
                    }
                  }))} />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch checked={permission.payment?.delete} onChange={() => setPermission(previous => ({
                    ...previous, payment: {
                      ...previous.payment,
                      delete: !permission.payment?.delete
                    }
                  }))} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </SoftBox>
        <SoftBox mt="50px" display="flex" justifyContent="end">
          <SoftButton variant="gradient" color="dark" size="small"
            onClick={handleUpdatePermission}
            disabled={!(context.permission.root || context.permission.customer.update)}
          >
            update permissions
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

EditCustomerPermission.propTypes = {
  customer: PropTypes.object.isRequired,
  setCustomer: PropTypes.func.isRequired
}
