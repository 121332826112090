import { Lesson } from "./Lesson";

export class Course {
  constructor(entity) {
    this.id = entity?.id || null;
    this.title = entity?.title || null;
    this.active = entity?.active || false;
    // class room
    this.classroom = entity?.classroom || false;
    this.classroomTitle = entity?.classroomTitle || null;
    this.scheduleLink = entity?.scheduleLink || null;
    // ask necessary documents
    this.consentReleaseForm = entity?.consentReleaseForm || false;
    this.consentReleaseFormDescription = entity?.consentReleaseFormDescription || null;
    this.consentReleaseDocumentId = entity?.consentReleaseDocumentId || null;

    this.shortDescription = entity?.shortDescription || null;
    this.description = entity?.description || "";
    this.thumbnailReference = entity?.thumbnailReference || null;
    /** @type {Array<Lesson>} */
    this.lessons = entity?.lessons?.map(lesson => new Lesson(lesson)) || [];
    this.price = entity?.price || 0;
    this.currency = entity?.currency || null;
    this.paymentMethods = entity?.paymentMethods || [];
    this.createdDate = entity?.createdDate instanceof Date ? entity?.createdDate : entity?.createdDate?.toDate() || null;
    this.updatedDate = entity?.updatedDate instanceof Date ? entity?.updatedDate : entity?.updatedDate?.toDate() || null;
  }
}