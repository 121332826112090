import { CURRENCY, PAYMENT_METHODS } from "views/courses/create/fragments/PricingInfo";

export class Payment {
  constructor(entity) {
    this.id = entity?.id || null;
    this.productId = entity?.productId || null;
    this.customerId = entity?.customerId || null;
    this.paymentId = entity?.paymentId || null;
    this.price = entity?.price || 0;
    this.totalPrice = entity?.totalPrice || 0; // + tax
    this.currency = entity?.currency || CURRENCY.find(currency => currency.value == "cad");
    /** @type {PAYMENT_METHODS} */
    this.paymentMethod = entity?.paymentMethod || null;
    this.createdDate = entity?.createdDate instanceof Date ? entity?.createdDate : entity?.createdDate?.toDate() || null;
    this.updatedDate = entity?.updatedDate instanceof Date ? entity?.updatedDate : entity?.updatedDate?.toDate() || null;
  }
}