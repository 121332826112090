import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { Context } from "contexts/contextState";
import { Course } from "models/Course";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { errorAlert, successAlert } from "views/components/Alerts";

export function DeleteLessonContentModal(props) {
  const { context } = useContext(Context);
  const [processing, setProcessing] = useState(false);

  async function handleDeleteLessonContent() {
    setProcessing(true);
    try {
      const course = new Course(props.course);
      const lesson = course.lessons.find(lesson => lesson.id == props.lesson.id);
      lesson.lessonContents = lesson.lessonContents.filter(lessonContent => lessonContent.id != props.lessonContent.id);
      await context.courseRepository.save(course);
      successAlert("Success!", "The lesson content has been deleted.");
      props.onClose?.();
    } catch (error) {
      console.log("handleDeleteLessonContent", error);
      errorAlert("Error!", "Failed to delete a lesson content in our database. Please check your connection and try again. If the issue persists, please contact our support team for assistance.");
    } finally {
      setProcessing(false);
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>
        Are you certain you want to delete this lesson content?
      </DialogTitle>
      <DialogContent>
        <SoftTypography variant="body2">
          If you delete this lesson content <b>{props.lessonContent?.title || ""}</b>, it cannot be recovered. <br />Are you sure you want to proceed?
        </SoftTypography>
        <br />
        <SoftTypography variant="body2">
          Click the <b>Delete</b> button to confirm or <b>Cancel</b> to go back.
        </SoftTypography>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flex="1">
          <SoftButton variant="text" color="error" onClick={handleDeleteLessonContent} disabled={processing}>Delete</SoftButton>
          <SoftButton variant="text" color="info" onClick={props.onClose} disabled={processing}>Cancel</SoftButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

DeleteLessonContentModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  course: PropTypes.any,
  lesson: PropTypes.any,
  lessonContent: PropTypes.any,
};