import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import FormField from "components/layouts/pages/account/components/FormField";
import { handleFormData, handleSelectFormData } from "services/HandleService";
import SoftButton from "components/SoftButton";
import SoftDatePicker from "components/SoftDatePicker";
import { User } from "models/User";
import { useContext } from "react";
import { Context } from "contexts/contextState";
import { errorAlert, successAlert } from "views/components/Alerts";

export function EditCustomerProfile(props) {
  const { context } = useContext(Context);

  async function handleUpdateInfos() {
    try {
      const customer = new User(props.customer);
      const customerResponse = await context.userRepository.saveUser(customer);
      console.log("handleUpdateInfos", customerResponse);
      successAlert("Success!", "The edits made to your customer have been successfully updated in the database.");
    } catch (error) {
      errorAlert("Error!", "An error occurred while updating the database with the edits made to your course. Please try again later.");
      console.log("handleUpdateInfos", error);
    }
  }

  return (
    <Card sx={{ overflow: "visible" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5">Basic Info</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} spacing={3}>
            <FormField label="email" name="email" value={props.customer.email} placeholder="example@email.com" inputProps={{ type: "email" }} disabled />
          </Grid>
          <Grid item xs={12} sm={6} spacing={3}>
            <FormField label="Password" name="password" value={context.permission.root ? props.customer.password : ""} placeholder="**********" disabled />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="first name" placeholder="Alec" name="firstName" value={props.customer.firstName} onChange={event => handleFormData(event, props.setCustomer)} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="last name" placeholder="Thompson" name="lastName" value={props.customer.lastName} onChange={event => handleFormData(event, props.setCustomer)} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%" >
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                      Province
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect value={CUSTOMER_PROVINCE.find(gender => gender.value == props.customer.province) || ""} options={CUSTOMER_PROVINCE} onChange={(obj) => handleSelectFormData("gender", obj.value, props.setCustomer)} />
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%" >
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                      Birth Date
                    </SoftTypography>
                  </SoftBox>
                  <SoftDatePicker value={props.customer.dob || null} onChange={(value) => handleSelectFormData("dob", [...value].shift(), props.setCustomer)} />
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="Address" placeholder="Street 10" name="address" value={props.customer.address} onChange={event => handleFormData(event, props.setCustomer)} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="city" placeholder="Toronto" name="city" value={props.customer.city} onChange={event => handleFormData(event, props.setCustomer)} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="Postal code" placeholder="Street 10" name="postalCode" value={props.customer.postalCode} onChange={event => handleFormData(event, props.setCustomer)} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="phone number" placeholder="+40 735 631 620" inputProps={{ type: "number" }} name="phone" value={props.customer.phone} onChange={event => handleFormData(event, props.setCustomer)} />
          </Grid>
        </Grid>
        <SoftBox mt="50px" display="flex" justifyContent="end">
          <SoftButton variant="gradient" color="dark" size="small"
            onClick={handleUpdateInfos}
            disabled={!(context.permission.root || context.permission.customer.update)}
          >
            update infos
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

EditCustomerProfile.propTypes = {
  customer: PropTypes.object.isRequired,
  setCustomer: PropTypes.func.isRequired
}

const CUSTOMER_PROVINCE = [
  { value: "Alberta", label: "Alberta" },
  { value: "British Columbia", label: "British Columbia" },
  { value: "Manitoba", label: "Manitoba" },
  { value: "New Brunswick", label: "New Brunswick" },
  { value: "Newfoundland and Labrador", label: "Newfoundland and Labrador" },
  { value: "Northwest Territories", label: "Northwest Territories" },
  { value: "Nova Scotia", label: "Nova Scotia" },
  { value: "Nunavut", label: "Nunavut" },
  { value: "Ontario", label: "Ontario" },
  { value: "Prince Edward Island", label: "Prince Edward Island" },
  { value: "Quebec", label: "Quebec" },
  { value: "Saskatchewan", label: "Saskatchewan" },
  { value: "Yukon", label: "Yukon" },
]