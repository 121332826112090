import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import FormField from "components/layouts/ecommerce/products/new-product/components/FormField";
import { handleFormData, handleSelectFormData } from "services/HandleService";

export function PricingInfo(props) {
  return (
    <SoftBox>
      <SoftTypography variant="h5">Pricing</SoftTypography>
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField type="number" label="price" name="price" value={props.formData?.price || ""} placeholder="99.00" onChange={(event) => handleFormData(event, props.setFormData)} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize" >
                Currency
              </SoftTypography>
            </SoftBox>
            <SoftSelect defaultValue={props.formData?.currency || ""} options={CURRENCY} onChange={(obj) => handleSelectFormData("currency", obj, props.setFormData)} />
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mt={1}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Payment methods
              </SoftTypography>
            </SoftBox>
            <SoftSelect value={props.formData?.paymentMethods || []}  options={PAYMENT_METHODS} size="large" isMulti onChange={(obj) => handleSelectFormData("paymentMethods", obj, props.setFormData)} />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

PricingInfo.propTypes = {
  formData: PropTypes.any.isRequired,
  setFormData: PropTypes.func.isRequired
};

export const CURRENCY = [
  { value: "usd", label: "USD" },
  { value: "cad", label: "CAD" },
  { value: "cny", label: "CNY" },
  { value: "eur", label: "EUR" },
  { value: "gbp", label: "GBP" },
  { value: "inr", label: "INR" },
];

export const PAYMENT_METHODS = [
  { value: "cc", label: "Credit card" },
  { value: "cash", label: "Pay in person" },
]