import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { Context } from "contexts/contextState";
import { User } from "models/User";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { errorAlert, successAlert } from "views/components/Alerts";

export function ClassroomModal(props) {
  const { context } = useContext(Context);
  const [processing, setProcessing] = useState(false);

  async function handleConfirmCourseClassroom() {
    setProcessing(true);
    try {
      const customer = new User(props.customer);
      customer.completedClassrooms.push(props.course.id);
      await context.userRepository.saveUser(customer);
      successAlert("Success!", "The customer classroom has been completed.").then(() => {
        window.location.reload();
      });
      props.onClose();
    } catch (error) {
      console.log("handleConfirmCourseClassroom", error);
      errorAlert("Error!", "Failed to complete customer classroom in our database. Please check your connection and try again. If the issue persists, please contact our support team for assistance.");
    } finally {
      setProcessing(false);
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>
        Are you certain you want to finish this classroom?
      </DialogTitle>
      <DialogContent>
        <SoftTypography variant="body2">
          If you confirm the classroom as finish for <b>{(props.course?.title || "").trim()}</b>, it cannot be recovered. <br />Are you sure you want to proceed?
        </SoftTypography>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flex="1">
          <SoftButton variant="text" color="error" onClick={props.onClose}>
            Cancel
          </SoftButton>
          <SoftButton
            disabled={!(context.permission.root || context.permission.customer.update) || processing}
            variant="text" color="info" onClick={handleConfirmCourseClassroom}
          >
            Confirm
          </SoftButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

ClassroomModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  course: PropTypes.object,
  customer: PropTypes.object,
};