import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { authentication } from "services/firebase/configs/firebase";


export class AuthenticationService {
  async signIn(email, password) {
    try {
      console.log(this.signIn.name, email, password)
      return await signInWithEmailAndPassword(authentication, email, password);
    } catch (error) {
      console.log('AuthFirebaseError', error.message);
    }
  }

  async signOut() {
    try {
      return await signOut(authentication);
    } catch (error) {
      console.log('AuthFirebaseError', error.message);
    }
  }
}