import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import FormField from "components/layouts/applications/wizard/components/FormField";
import { Context } from "contexts/contextState";
import { Course } from "models/Course";
import { LESSON_STATUS, Lesson } from "models/Lesson";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { errorAlert, successAlert } from "views/components/Alerts";
import { ProgressBarModal } from "views/components/ProgressBarModal";
import { handleFormData, handleSelectFormData } from "services/HandleService";

export function CreateLessonModal(props) {
  const { context } = useContext(Context);
  const [formLesson, setFormLesson] = useState({});
  const [processing, setProcessing] = useState(false);

  async function handleSaveLesson() {
    setProcessing(true);
    const course = new Course(props.course);
    const lesson = new Lesson(formLesson);
    try {
      lesson.createdDate = new Date();
      course.lessons.push(lesson);
      const courseResponse = await context.courseRepository.save(course);
      console.log("handleSaveVideoLesson", courseResponse);
      successAlert("Success!", "The video lesson has been successfully created.").then(() => {
        window.location.reload();
      });
      setFormLesson({});
      props.onClose();
    } catch (error) {
      console.log(error);
      errorAlert("Error!", "Failed to create a video in our database. Please check your connection and try again. If the issue persists, please contact our support team for assistance.");
    } finally {
      setProcessing(false);
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <ProgressBarModal open={processing} percent={100} onClose={props.onClose} />
      <SoftBox mx="16px" mt="16px">
        <SoftTypography variant="h5">Lesson Information</SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          If you confirm the creation, the last video lesson will be deleted.
        </SoftTypography>
      </SoftBox>
      <DialogContent>
        <SoftBox display="flex" flex="1" flexDirection="column" mt={1} minWidth="480px">
          <FormField type="text" label="title" name="title" value={formLesson.title || ""} placeholder="eg. Oreo Overloaded" onChange={(event) => handleFormData(event, setFormLesson)} />
          <SoftBox mt={1}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                Status
              </SoftTypography>
            </SoftBox>
            <SoftSelect value={formLesson.active != void 0 ? LESSON_STATUS[formLesson.active ? 0 : 1] : LESSON_STATUS[1]} options={LESSON_STATUS} onChange={(obj) => handleSelectFormData("active", obj.value, setFormLesson)} />
          </SoftBox>
          <SoftBox mt={1}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">Description</SoftTypography>
              <SoftTypography variant="caption" fontWeight="regular" color="text">(optional)</SoftTypography>
            </SoftBox>
            <FormField type="text" name="description" value={formLesson.description || ""} onChange={(event) => handleFormData(event, setFormLesson)} />
          </SoftBox>
        </SoftBox>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flex="1">
          <SoftButton variant="text" color="info" onClick={props.onClose} disabled={processing}>Cancel</SoftButton>
          <SoftButton variant="text" color="info"
            onClick={handleSaveLesson}
            disabled={!(context.permission.root || context.permission.course.update)}
          >
            Confirm
          </SoftButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

CreateLessonModal.propTypes = {
  course: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}