
export class Lesson {
  constructor(entity) {
    this.id = entity?.id || Date.now();
    this.title = entity?.title || null;
    this.active = entity?.active || false;
    /** @type {Array<LessonContent>} */
    this.lessonContents = entity?.lessonContents?.map(content => new LessonContent(content)) || [];
  }
}

export class LessonContent {
  constructor(entity) {
    this.id = entity?.id || Date.now();
    this.title = entity?.title || null;
    this.type = entity?.type || LESSON_CONTENT_ENUM.TEXT;
    this.videoLink = entity?.videoLink || null;
    this.archiveId = entity?.archiveId || null;
    this.questionId = entity?.questionId || null;
    this.examId = entity?.examId || null;
    this.text = entity?.text || null;
  }
}

export const LESSON_CONTENT_ENUM = {
  VIDEO: "VIDEO",
  ARCHIVE: "ARCHIVE",
  QUESTION: "QUESTION",
  EXAM: "EXAM",
  TEXT: "TEXT"
}

export const LESSON_STATUS = [
  {
    label: "Active",
    value: true
  },
  {
    label: "Disabled",
    value: false
  },
]