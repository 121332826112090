import { Context, contextData } from "contexts/contextState";
import { authentication } from "../services/firebase/configs/firebase";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ACCOUNT_PERMISSION } from "models/User";
export function Root(props) {
  const [context, setContext] = useState(contextData);

  const location = useLocation();
  const navigate = useNavigate();
  const updateContext = (contextData) => setContext(previous => ({ ...previous, ...contextData }));

  /**
   * @param {import("firebase/auth").User} user 
   */
  function handleAuthStateChanged(user) {
    updateContext({ user: user });

    if (!user && location.pathname.startsWith("/admin")) {
      navigate("/authentication/sign-in");
    } else if (user && !location.pathname.startsWith("/admin")) {
      navigate("/admin/customers/all");
    }
    if (user) {
      context.userRepository.findUserById(user.uid).then(response => {
        updateContext({ permission: response?.permission || ACCOUNT_PERMISSION });
      });
      user.getIdToken().then(token => localStorage.setItem("firebaseToken", token));
    } else {
      localStorage.removeItem("firebaseToken");
    }
  }

  useEffect(() => {
    // update user login
    authentication.onAuthStateChanged(handleAuthStateChanged);
  }, []);

  return (
    <Context.Provider value={{ context, setContext: updateContext }}>
      {props.children}
    </Context.Provider>
  )
}

Root.propTypes = {
  children: PropTypes.any
}