import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useState } from "react";
import { DeleteCustomerCourseModal } from "./DeleteCustomerCourseModal";

export function CourseActionCell(props) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <SoftBox display="flex" alignItems="center">
      <DeleteCustomerCourseModal open={openDeleteModal} course={props.course} customer={props.customer} onClose={() => setOpenDeleteModal(false)} />
      <SoftTypography component={Link} to={"/admin/courses/all/edit/" + props.course.id} variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Preview Course" placement="top">
          <Icon>visibility</Icon>
        </Tooltip>
      </SoftTypography>
      <SoftBox mx={2}>
        <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} onClick={() => setOpenDeleteModal(true)}>
          <Tooltip title="Delete Course" placement="left">
            <Icon>delete</Icon>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

CourseActionCell.propTypes = {
  course: PropTypes.any.isRequired,
  customer: PropTypes.any.isRequired
};