export const STORAGE_TYPE = {
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  ARCHIVE: "ARCHIVE",
  CERTIFICATION: "CERTIFICATION",
  DOCUMENT: "DOCUMENT",
  CONSENT: "CONSENT"
}

export class FileStorage {
  constructor(fileStorage) {
    this.id = fileStorage?.id;
    this.type = fileStorage?.type || STORAGE_TYPE.IMAGE;
    this.fileName = fileStorage?.fileName || "";
    this.filePath = fileStorage?.filePath || "";
    this.fileUrl = fileStorage?.fileUrl || "";
    this.fileUri = fileStorage?.fileUri || "";
    this.fileSize = fileStorage?.fileSize || 0;
    this.createdDate = fileStorage?.createdDate instanceof Date ? fileStorage?.createdDate : fileStorage?.createdDate?.toDate() || null;
  }
}