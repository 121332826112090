import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { Context } from "contexts/contextState";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { STORAGE_TYPE } from "models/FileStorage";

export function ConsentsModal(props) {
  const { context } = useContext(Context);
  const [storageFiles, setStorageFiles] = useState([]);

  async function handleStorageLoader() {
    const storageResponse = await context.fileStorageRepository.findAllFileByType(STORAGE_TYPE.CONSENT);
    setStorageFiles(storageResponse);
  }

  function handleConsentsView(storage) {
    window.open(storage.fileUrl, "_blank");
  }

  useEffect(() => {
    handleStorageLoader();
  }, []);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <SoftBox mx="16px" mt="16px">
        <SoftTypography variant="h5">Consents</SoftTypography>
      </SoftBox>
      <DialogContent>
        <SoftBox display="flex" flex="1" flexDirection="column" mt={1} minWidth="480px">
          <SoftBox mt={1}>
            <SoftBox display="flex">
              {props.consents.map(consent => {
                const storage = storageFiles.find(storage => consent.documentId == storage.id);
                return (
                  <Box display="flex" borderBottom="1px solid #ccc" py={2} gap="20px">
                    <SoftTypography className="flex" variant="body1">{storage.fileName}</SoftTypography>
                    <SoftBox display="flex" flexContent="end">
                      <SoftButton variant="gradient" color="info" size="small" onClick={() => handleConsentsView(storage)}>
                        View
                      </SoftButton>
                    </SoftBox>
                  </Box>
                )
              })}
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flex="1">
          <SoftButton variant="text" color="info" onClick={props.onClose}>Close</SoftButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

ConsentsModal.propTypes = {
  consents: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func
}