import Card from "@mui/material/Card";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Table from "components/examples/Tables/Table";
import { Context } from "contexts/contextState";
import { useContext, useEffect, useState } from "react";
import { LESSON_TABLE_COLUMNS } from "views/constants/tables";
import { LessonActionCell } from "../components/table/LessonActionCell";
import SoftButton from "components/SoftButton";
import PropTypes from "prop-types";
import { CreateLessonModal } from "../components/CreateLessonModal";

export function ClassLesson(props) {
  const { context } = useContext(Context);
  const [lessons, setLessons] = useState([]);
  const [showUploadLessonModal, setShowUploadLessonModal] = useState();

  function handlePageLoader() {
    setLessons(props.course.lessons?.map(lesson => ({
      title: ["", <SoftTypography variant="p" color="inherit">{lesson.title}</SoftTypography>],
      // description: context.formatterService.formatStringShortText(lesson.description) || "",
      privacy: lesson.privacy,
      status: lesson.active ? <SoftBadge variant="contained" color="success" size="xs" badgeContent="Active" container /> : <SoftBadge variant="contained" color="error" size="xs" badgeContent="Disabled" container />,
      created: context.formatterService.formatDate(lesson.createdDate?.toDate()),
      action: <LessonActionCell course={props.course} lesson={lesson} />
    })));
  }

  useEffect(() => {
    handlePageLoader();
  }, [props.course]);

  return (
    <Card>
      <CreateLessonModal open={Boolean(showUploadLessonModal)} course={props.course} onClose={() => setShowUploadLessonModal(void 0)} />
      <SoftBox p={3}>
        <SoftBox display="flex" justifyContent="space-between">
          <SoftTypography variant="h5" fontWeight="bold">
            Lessons
          </SoftTypography>
          <SoftButton variant="outlined" color="info" size="small"
            onClick={() => setShowUploadLessonModal(true)}
            disabled={!(context.permission.root || context.permission.course.update)}
          >
            Add Lesson
          </SoftButton>
        </SoftBox>
        <SoftBox mt={1}>
          <Table columns={LESSON_TABLE_COLUMNS} rows={lessons} />
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

ClassLesson.propTypes = {
  course: PropTypes.object.isRequired,
  setCourse: PropTypes.func.isRequired
}