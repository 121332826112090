import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { Context } from "contexts/contextState";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { successAlert } from "views/components/Alerts";
import { ProgressBarModal } from "views/components/ProgressBarModal";
import { SoftDropzone } from "views/components/SoftDropzone";
import { handleSelectFormData } from "services/HandleService";
import { STORAGE_TYPE } from "models/FileStorage";
import { CERTIFICATION_TYPE, Certification, User } from "models/User";

export function DocumentModal(props) {
  const { context } = useContext(Context);
  const [processing, setProcessing] = useState(false);
  const [percent, setPercent] = useState(0);
  const [documentFile, setDocumentFile] = useState({});

  async function handleDocumentUpdate() {
    setProcessing(true);
    try {
      if (documentFile.file) {
        const certificationStorageResponse = await context.fileStorageRepository.uploadFile(documentFile.file, STORAGE_TYPE.DOCUMENT, (progressPercent) => {
          setPercent(progressPercent);
        });
        console.log("handleDocumentUpdate", certificationStorageResponse);
        successAlert("Success!", "The certification has been successfully updated.");
        setDocumentFile({});
        props.onClose();
      }
    } catch (error) {
      console.log("handleCertificationUpdate", error);
    } finally {
      setProcessing(false);
    }
  }

  useEffect(() => {
    console.log(documentFile);
  }, [documentFile]);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <ProgressBarModal open={processing} percent={percent} onClose={props.onClose} />
      <SoftBox mx="16px" mt="16px">
        <SoftTypography variant="h5">Document</SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          If you confirm the update, the document will be send to our database.
        </SoftTypography>
      </SoftBox>
      <DialogContent>
        <SoftBox display="flex" flex="1" flexDirection="column" mt={1} minWidth="480px">
          <SoftBox mt={1}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                Select Document
              </SoftTypography>
            </SoftBox>
            <SoftDropzone file={documentFile.file} options={{
              addRemoveLinks: true,
              maxFiles: 1, // Allow only one file
              acceptedFiles: "application/*", // Accept only pdf files
              dictDefaultMessage: "Drop a document file here or click to upload",
              init: function () {
                this.on("addedfile", (file) => {
                  if (this.files.length > 1) {
                    this.removeFile(this.files[0]);
                  }
                  handleSelectFormData("file", file, setDocumentFile);
                });
                this.on("removedfile", (file) => {
                  handleSelectFormData("file", null, setDocumentFile);
                });
              },
            }} />
          </SoftBox>
        </SoftBox>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flex="1">
          <SoftButton variant="text" color="info" onClick={props.onClose} disabled={processing}>Cancel</SoftButton>
          <SoftButton variant="text" color="info"
            onClick={handleDocumentUpdate}
            disabled={!(context.permission.root || context.permission.course.update)}
          >
            Confirm
          </SoftButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

DocumentModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}