/**
 * Object containing environment variables for the application.
 * @typedef {Object} Env
 * @property {Object} FIREBASE - Object containing Firebase configuration variables.
 * @property {string} FIREBASE.apiKey - Firebase API key.
 * @property {string} FIREBASE.authDomain - Firebase auth domain.
 * @property {string} FIREBASE.projectId - Firebase project ID.
 * @property {string} FIREBASE.storageBucket - Firebase storage bucket.
 * @property {string} FIREBASE.messagingSenderId - Firebase messaging sender ID.
 * @property {string} FIREBASE.appId - Firebase app ID.
 * @property {string} FIREBASE.measurementId - Firebase measurement ID.
 * @property {Object} COLLECTION - Object containing Firebase collection variables.
 * @property {string} COLLECTION.ANALYSIS - Firebase collection
 * @property {string} COLLECTION.ACCOUNTS - Firebase collection
 * @property {string} COLLECTION.FILE_STORAGES - Firebase collection
 * @property {string} COLLECTION.COURSES - Firebase collection
 * @property {string} COLLECTION.QUESTIONS - Firebase collection
 * @property {string} COLLECTION.PAYMENTS - Firebase collection
 * @property {Object} API - Object containing API host and endpoints variables.
 * @property {string} API.HOST - Firebase collection
 * @property {Object} API.ENDPOINTS - Firebase collection
 * @property {string} API.ENDPOINTS.VIDEO_UPLOAD - Firebase collection
 * @property {string} API.ENDPOINTS.IMAGE_UPLOAD - Firebase collection
 * @property {Object} WEB_CLIENT - Object containing API host and endpoints variables.
 * @property {string} WEB_CLIENT.HOST - Firebase collection
 * @property {Object} WEB_CLIENT.URIS - Firebase collection
 * @property {string} WEB_CLIENT.URIS.COURSES - Firebase collection
 */

const env = {
  production: {
    FIREBASE: {
      // apiKey: "AIzaSyAWdq4o3HqDMwp7Pg7KXZ9I2NqOidqiy1w",
      apiKey: "AIzaSyDi8Z8Dxxo5eOMKGdD3RilGUn_NAUYtQLA",
      authDomain: "hydra-securityprod.firebaseapp.com",
      databaseURL: "https://hydra-securityprod.firebaseio.com",
      projectId: "hydra-securityprod",
      storageBucket: "hydra-securityprod.appspot.com",
      messagingSenderId: "181041556618",
      appId: "1:181041556618:web:b507e2ab7db683d7d05173"
    },
    COLLECTION: {
      ANALYSIS: "analysis",
      ACCOUNTS: "user_data",
      FILE_STORAGES: "file-storages",
      COURSES: "courses",
      QUESTIONS: "questions",
      PAYMENTS: "payments"
    },
    API: {
      HOST: "https://us-central1-hydra-securityprod.cloudfunctions.net/api",
      ENDPOINTS: {
        VIDEO_UPLOAD: "/upload/video",
        IMAGE_UPLOAD: "/upload/image",
      }
    },
    WEB_CLIENT: {
      HOST: "http://localhost",
      URIS: {
        COURSES: "/courses"
      }
    }
  },
  development: {
    FIREBASE: {
      apiKey: "AIzaSyDD9-qaawHZ1EkQT3Bbifdnhz_l7A6f3oM",
      authDomain: "hydra-security.firebaseapp.com",
      databaseURL: "https://hydra-security.firebaseio.com",
      projectId: "hydra-security",
      storageBucket: "hydra-security.appspot.com",
      messagingSenderId: "377084884325",
      appId: "1:377084884325:web:b80da8952d6ed6c38d5d8c"
    },
    COLLECTION: {
      ANALYSIS: "analysis",
      ACCOUNTS: "user_data",
      FILE_STORAGES: "file-storages",
      COURSES: "courses",
      QUESTIONS: "questions",
      PAYMENTS: "payments"
    },
    API: {
      HOST: "https://us-central1-hydra-security.cloudfunctions.net/api",
      ENDPOINTS: {
        VIDEO_UPLOAD: "/upload/video",
        IMAGE_UPLOAD: "/upload/image"
      }
    },
    WEB_CLIENT: {
      HOST: "http://localhost",
      URIS: {
        COURSES: "/courses"
      }
    }
  }
};

const environmentResolver = (process.env.REACT_APP_ENV || "development");
console.log("Environment(Web): %s", environmentResolver);

/**
 * Object containing the current environment's configuration variables.
 * @type {Env}
 */
export const environment = env[environmentResolver];